import React, { useContext, useEffect, useState } from "react";
import { getURLParams, t } from "../../helpers";
import Header from "../../components/Header";
import { Link, useParams, useLocation } from "react-router-dom";
import { AccountContext } from "../../context/account";
import SimpleToast from "../../components/SimpleToast";
import axios from "axios";

async function getSubscriptions() {
  let response = await fetch("https://rest-api.cocunat.com/v2/subscriptions/", {
    method: "GET",
    headers: {
      Authorization: `JWT ${localStorage.getItem("jwt")}`,
      "Content-Type": "application/json",
    },
    mode: "cors", // <---
    cache: "default",
  });
  let data = await response.json();
  return data;
}

function Account() {
  let { lang, country } = useParams();
  const { subscriptions, getSubscriptionType } = useContext(AccountContext);
  const [clubSubscription, setClubSubscription] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [successParam, setSuccessParam] = useState(false);
  const logout = () => {
    localStorage.removeItem("jwt");
    window.location.href = `/${lang}-${country}/login`;
  };
  const location = useLocation();

  const fetchSubscriptions = async () => {
    console.log("fetching subscriptions");
    try {
      const subs = await getSubscriptions();
      if (subs.results && subs.results.length > 0) {
        setClubSubscription(subs.results[0]);
      }
    } catch (e) {
      console.log("-------");
      console.log(e);
      console.log(e.message);
      console.log(e.response);
      console.log("-------");
    }
  };

  useEffect(() => {
    console.log("here");
    console.log(subscriptions);
    fetchSubscriptions();
  }, [subscriptions]);

  function handleToast() {
    let urlParams = getURLParams(location.search);
    if (urlParams.hasOwnProperty("success")) {
      setShowMessage(true);
      if (urlParams.success === "true") {
        setSuccessParam(true);
      } else {
        setSuccessParam(false);
      }
    }
  }

  useEffect(() => {
    handleToast();
  }, []);

  return (
    <>
      <Header />
      {showMessage && (
        <SimpleToast
          onClose={() => setShowMessage(false)}
          title={successParam ? t("sub_success_title") : t("error_saving")}
          message={""}
          success={successParam}
        />
      )}
      <div className="container">
        <p
          style={{
            backgroundImage:
              "url('https://global.cocunat.com/photos/hero-65.jpg')",
            height: "80px",
            textAlign: "center",
            backgroundSize: "cover",
            lineHeight: "80px",
            fontWeight: "bold",
            fontFamily: "Brown-Bold",
            fontSize: "16px",
            margin: "0px",
          }}
        >
          MI CUENTA
        </p>
        <div id="account">
          <p>{t("welcome_account", { name: "" })}</p>
          <ul>
            {clubSubscription && (
              <li>
                <Link
                  to={`/${lang}-${country}/account/profile/subscriptions/${clubSubscription.subscription}/${clubSubscription.id}`}
                >
                  COCUNAT CLUB
                  <img
                    src="https://global.cocunat.com/angle-down-v2.png"
                    alt=">"
                  />
                </Link>
              </li>
            )}
            <li  style={{ display: "none" }}>
              <Link
                to={`/${lang}-${country}/account/profile/subscriptions/list`}
              >
                {t("my_subscriptions")}
                <img
                  src="https://global.cocunat.com/angle-down-v2.png"
                  alt=">"
                />
              </Link>
            </li>
            <li style={{ display: "none" }}>
              <Link to={`/${lang}-${country}/account/profile/payment_methods`}>
                {t("my_payment_methods")}
                <img
                  src="https://global.cocunat.com/angle-down-v2.png"
                  alt=">"
                />
              </Link>
            </li>
          </ul>
          <button
            onClick={logout}
            style={{
              background: "none",
              border: 0,
              color: "red",
              marginTop: 20,
            }}
          >
            {t("logout")}
          </button>
        </div>
      </div>
    </>
  );
}

export default Account;

import { useEffect, useState } from "react";

const Error404 = () => {
  const [country, setCountry] = useState("XX");
  const [language, setLanguage] = useState("en");
  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = await (
          await fetch("https://geo.cocunat.workers.dev/")
        ).json();
        setCountry(data.country);
        setLanguage(data.language.toLowerCase());
      } catch (err) {}
    };
    fetchData();
  }, []);
  return (
    <>
      <header
        style={{ height: "70px", lineHeight: "70px", textAlign: "center" }}
      >
        <img
          decoding="async"
          alt="Cocunat"
          height="22"
          width="143"
          src="https://resizing.cocunat.com/r/?quality=1&amp;fit=cover&amp;image=https://global.cocunat.com/photos/logo.png&amp;width=143&amp;height=22"
        />
      </header>
      <div className="container">
        <h1 style={{ textAlign: "center" }}>
          Error 404 <br /> <small>Page not found</small>
        </h1>
        <a
          href={`https://cocunat.com/${language}-${country}/`}
          style={{
            background: "#ff0000",
            color: "#fff",
            display: "block",
            width: 300,
            height: 60,
            lineHeight: "60px",
            margin: "50px auto",
            textAlign: "center",
            textTransform: "uppercase",
            textDecoration: "none",
          }}
        >
          Cocunat.com
        </a>
      </div>
    </>
  );
};

export default Error404;

import { createContext, useState, useEffect } from "react";
import { Subscription } from "@cocunat/cocunat-core-js";
import { getAccountSubscriptions, saveSubscription } from "../logic/account";
import { getAllProductsGroupedByCategory } from "../logic/products";
import { getCountryRegion } from "../logic/regions";

const AccountContext = createContext(null);

function AccountContextProvider({ children }) {
  const [subscriptions, setSubscriptions] = useState(null);
  const [region, setRegion] = useState(null);
  /**
   * @description Adds the subscription to the subscriptions object using the subscription uuid as key
   */
  function addSubscription(uuid, subscription) {
    setSubscriptions({ ...subscriptions, [uuid]: subscription });
  }

  /**
   * @description Updates the subscription in the subscriptions object using the subscription uuid as key
   */
  function updateSubscription(uuid, subscription, callback) {
    let updatedSubscription = { ...subscriptions[uuid], ...subscription };
    if (subscription.packs_variations === null) {
      delete updatedSubscription.packs_variations;
    }
    setSubscriptions({ ...subscriptions, [uuid]: updatedSubscription });
  }

  function addProduct(uuid, productData) {
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let subscriptionObject = new Subscription({ ...subscription }).addProduct(
      productData
    );
    updateSubscription(subscription.uuid, subscriptionObject);
  }

  function plusOneProduct(uuid, productId) {
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let subscriptionObject = new Subscription({ ...subscription }).plusOne(
      productId
    );
    updateSubscription(subscription.uuid, subscriptionObject);
  }

  function minusOneProduct(uuid, productId) {
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let subscriptionObject = new Subscription({ ...subscription }).minusOne(
      productId
    );
    updateSubscription(subscription.uuid, subscriptionObject);
  }

  function removeProduct(uuid, productId) {
    console.log("removeProduct", uuid, productId);
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let subscriptionObject = new Subscription({
      ...subscription,
    }).removeProduct(productId);
    updateSubscription(subscription.uuid, subscriptionObject);
  }
  function hasProduct(uuid, productId) {
    let subscription = subscriptions[uuid];
    if (!subscription) return false;
    return (
      new Subscription({ ...subscription }).findProductIndex(productId) > -1
    );
  }

  function getUpsellOrderline(uuid, productId) {
    let subscription = subscriptions[uuid];
    if (!subscription) return false;
    const sub = new Subscription({ ...subscription });
    const index = sub.findProductIndex(productId);

    if (index === -1) return null;
    return sub.subscription.upsell_orderlines[index];
  }

  function isNextProduct(uuid, productId) {
    let subscription = subscriptions[uuid];
    if (!subscription) return false;
    return new Subscription({ ...subscription }).isNextProduct(productId);
  }

  function getSubscriptionType(subscriptionType) {
    let subscriptions = Object.values(subscriptions);
    for (let subscription of subscriptions) {
      if (subscription.subscription_type === subscriptionType) {
        return subscription;
      }
    }
    return null;
  }

  function getSubscriptionTotal(uuid) {
    let subscription = subscriptions[uuid];
    if (!subscription) return null;
    return new Subscription({ ...subscription }).total();
  }

  useEffect(() => {}, []);

  async function patchSubscription(uuid) {
    let subscription = subscriptions[uuid];
    if (!subscription) return;
    let response = await saveSubscription(subscription);
    if (response.status === 200) {
      updateSubscription(uuid, { success: true });
    } else {
      updateSubscription(uuid, { success: false });
    }
  }

  async function getCategoriesAndProductsSlugs(lang, country, promo_code) {
    let categories = null;
    let productsSlugs = null;
    let response = await getAllProductsGroupedByCategory(
      lang,
      country,
      promo_code
    );
    if (response.status === 200) {
      categories = {
        65: [],
        66: [],
        67: [],
        80: [],
        90: [],
        91: [],
        "pre-checkout": [],
        "happy-hair": [],
        default: [],
      };
      productsSlugs = {};
      let categoryMap = response.data.categories;
      let products = response.data.products;

      for (let i = 0; i < products.length; i++) {
        let product = products[i];

        if (categoryMap["65"].includes(product["id"])) {
          categories[65].push(product);
        }
        if (categoryMap["66"].includes(product["id"])) {
          categories[66].push(product);
        }
        if (categoryMap["67"].includes(product["id"])) {
          categories[67].push(product);
        }
        if (categoryMap["80"].includes(product["id"])) {
          categories[80].push(product);
        }
        if (categoryMap["90"].includes(product["id"])) {
          categories[90].push(product);
        }
        if (categoryMap["91"].includes(product["id"])) {
          categories[91].push(product);
        }
        if (categoryMap["pre-checkout"].includes(product["id"])) {
          categories["pre-checkout"].push(product);
        }
        if (categoryMap["happy-hair"].includes(product["id"])) {
          categories["happy-hair"].push(product);
        }

        categories["default"].push(product);
        if (product.slug) {
          productsSlugs[product.slug] = product;
        }
      }
    }
    return { categories, productsSlugs };
  }
  return (
    <AccountContext.Provider
      value={{
        updateSubscription,
        hasProduct,
        subscriptions,
        getSubscriptionType,
        addSubscription,
        addProduct,
        removeProduct,
        patchSubscription,
        isNextProduct,
        getSubscriptionTotal,
        getUpsellOrderline,
        plusOneProduct,
        minusOneProduct,
        getCategoriesAndProductsSlugs,
        region,
        setRegion,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export { AccountContextProvider, AccountContext };

import { useState, useEffect } from "react";

function Modal(props) {
  return (
    <>
      {props.isOpen && (
        <div className="modal-screen" style={props.wrapperStyle}>
          <div className="modal-container" style={props.containerStyle}>
            {!props.hideCloseButton && (
              <button
                className="close-button"
                onClick={() => props.setIsOpen(false)}
              >
                ✕
              </button>
            )}
            <div className="modal-content" style={props.contentStyle}>
              {props.children}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;

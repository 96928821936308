import React, { useState } from "react";
import { t } from "../../helpers";
import Modal from "../Modal";

const SelectToneModal = React.memo(
  ({
    isOpenSelectToneModal,
    setIsOpenSelectToneModal,
    options,
    onClickCallback,
  }) => {
    const [selectedTone, setSelectedTone] = useState(null);
    const addToCart = (option) => {
      if (option) {
        setSelectedTone(option);
        onClickCallback(option);
      }
    };
    return (
      <>
        {options && (
          <Modal
            isOpen={isOpenSelectToneModal}
            setIsOpen={setIsOpenSelectToneModal}
          >
            {options && (
              <div style={{ textAlign: "center" }}>
                <h1 className="innerTitle">{t("selectYourShade")}</h1>
                <div
                  style={{
                    display: "inline-block",
                    width: "auto",
                    maxWidth: "100%",
                    margin: "auto",
                    textAlign: "left",
                  }}
                >
                  {options.map((option, i) => {
                    return (
                      <div
                        key={`option-${i}`}
                        style={{
                          overflow: "hidden",
                          position: "relative",
                          width: "calc(175px * (4 / 5))",
                          maxWidth: "calc(175px * (4 / 5))",
                          maxHeight: "175px",
                          height: "175px",
                          margin: "5px",
                          display: "inline-block",
                          position: "relative",
                        }}
                      >
                        {selectedTone && option.id === selectedTone.id ? (
                          <img
                            className="selectedTone"
                            onClick={() => addToCart(option)}
                            src={option.img}
                            alt={`tone-${i}`}
                            style={{ maxWidth: "100%" }}
                          />
                        ) : (
                          <img
                            onClick={() => addToCart(option)}
                            src={option.img}
                            alt={`tone-${i}`}
                            style={{ maxWidth: "100%" }}
                          />
                        )}
                        <span
                          style={{
                            position: "absolute",
                            color: "#ffffff",
                            bottom: "10px",
                            left: "10px",
                            fontFamily: "Brown-Regular",
                            fontSize: 24,
                          }}
                        >
                          {option.text}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Modal>
        )}
      </>
    );
  }
);

export default SelectToneModal;

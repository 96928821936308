import { t } from "../../../helpers";
import React, { useState } from "react";
import Modal from "../../../components/Modal";

export default function ClubProductChoice({
  info,
  onClick = () => {},
  isNextProduct,
}) {
  const [isOpenClubProductModal, setIsOpenClubProductModal] = useState(false);

  const openModal = (e) => {
    e.stopPropagation();
    setIsOpenClubProductModal(true);
  };

  return (
    <>
      <Modal
        isOpen={isOpenClubProductModal}
        setIsOpen={setIsOpenClubProductModal}
      >
        {info && (
          <div className="modalInnerProductClub">
            <h1 className="innerTitle">{info.title.toUpperCase()}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: `${info.text}`,
              }}
            ></p>
          </div>
        )}
      </Modal>
      <div
        className={
          isNextProduct ? "clubproduct-item selected" : "clubproduct-item"
        }
        style={{ display: "flex" }}
        onClick={onClick}
      >
        {isNextProduct && <div className="triangle"></div>}
        <div style={{ width: "52%", paddingRight: "2%" }}>
          <strong
            dangerouslySetInnerHTML={{
              __html: `${info.title}`,
            }}
          ></strong>
          <p
            dangerouslySetInnerHTML={{
              __html: `${info.summary}`,
            }}
          ></p>
          <a
            onClick={(e) => openModal(e)}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            className="knowMore"
          >
            {t("knowMore")}
          </a>
        </div>
        <div
          style={{
            backgroundImage: `url("${info.img}")`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            width: "48%",
          }}
        ></div>
      </div>
    </>
  );
}

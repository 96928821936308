import React, { useEffect, useState } from "react";
import { t } from "../helpers";
import { Redirect, useParams } from "react-router-dom";
import { loginUserSSO, requestUserSSO } from "../logic/account";
import Modal from "../components/Modal";
import Button from "./Button";

function LoginForm({
  setAddress = (address) => {},
  address = {},
  redirectSuccess,
  buttonTitle,
  style,
  defaultEmail = "",
  newClub = false,
}) {
  let { lang, country } = useParams();
  let [email, setEmail] = useState(defaultEmail);
  let [redirectTo, setRedirectTo] = useState("");
  let [password, setPassword] = useState("");
  let [openInputCode, setOpenInputCode] = useState(false);
  let [openAskCodeReceived, setOpenAskCodeReceived] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  useEffect(() => {
    setEmail(defaultEmail);
  }, [defaultEmail]);

  useEffect(() => {
    let copyAddress = { ...address };
    copyAddress.email = email;
    setAddress(copyAddress);
  }, [email]);

  if (!buttonTitle) {
    buttonTitle = t("login");
  }

  const signIn = async () => {
    try {
      let response = await loginUserSSO({ email, code: password });
      if (response.status === 200) {
        localStorage.setItem("jwt", response.data.token);
        if (redirectSuccess === `/${lang}-${country}/account/`) {
          window.location.href = redirectSuccess;
        } else {
          setRedirectTo(redirectSuccess);
        }
      }
    } catch (err) {
      setSuccessMsg(null);
      setPassword("");
      if (err.response && err.response.status === 400) {
        setError(t("error_login_400"));
      } else if (err.response && err.response.status === 500) {
        setError(t("error_500"));
      } else {
        setError(t("error_unknown"));
      }
    }
  };

  const requestSS0 = async () => {
    if (email) {
      try {
        setIsLoading(true);
        let response = await requestUserSSO({ email, lang });
        if (response.status === 201) {
          setOpenAskCodeReceived(true);
          setIsLoading(false);
        }
      } catch (err) {
        setSuccessMsg(null);
        if (err.response && err.response.status === 400) {
          setError(t("error_login_400"));
        } else if (err.response && err.response.status === 500) {
          setError(t("error_500"));
        } else {
          setError(t("error_unknown"));
        }
      }
    }
  };

  if (redirectTo) {
    return <Redirect push to={redirectTo} />;
  }

  return (
    <div style={style}>
      <Modal setIsOpen={setOpenInputCode} isOpen={openInputCode}>
        {error && <div className="alert alert-danger">⚠ {error}</div>}
        {successMsg && <div className="alert alert-success">{successMsg}</div>}
        <div className="input-group">
          <label style={{ marginBottom: 10, fontSize: 16, display: "block" }}>
            {t("sso_code")}
          </label>
          <p>{t("sso_code_placeholder")}</p>
          <input
            name="password"
            id="password"
            placeholder="Code"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </div>
        <button
          className="button primary regular wide"
          style={{
            marginTop: 20,
            fontFamily: "Brown-Regular",
            fontSize: "16px",
          }}
          onClick={signIn}
        >
          {t("login")}
        </button>
      </Modal>

      <Modal setIsOpen={setOpenAskCodeReceived} isOpen={openAskCodeReceived}>
        <p>{t("code_sent_to_email")}</p>
        <p>
          <strong>{email}</strong>
        </p>
        <p>{t("check_email_code", { buttonText: t("sub_continue") })}</p>
        <p>{t("check_email_code_delay")}</p>
        <button
          className="button primary regular wide"
          style={{
            marginTop: 20,
            fontFamily: "Brown-Regular",
            fontSize: "16px",
          }}
          onClick={() => {
            setOpenAskCodeReceived(false);
            setOpenInputCode(true);
          }}
        >
          {t("sub_continue")}
        </button>
      </Modal>

      <div className="input-group">
        <label style={{ marginBottom: 10, fontSize: 16, display: "block" }}>
          Email
        </label>
        <input
          name="email"
          id="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
        />
      </div>
      <p
        dangerouslySetInnerHTML={{
          __html: t("sso_code_request", { buttonText: buttonTitle }),
        }}
      ></p>
      <Button
        className="button primary regular wide"
        style={{ fontFamily: "Brown-Regular", fontSize: "16px" }}
        onClick={requestSS0}
        data-button-id="login"
        loading={isLoading}
        size="big"
        width="wide"
      >
        <span>{buttonTitle}</span>
      </Button>
    </div>
  );
}

export default LoginForm;

import "./styles.css";
import { t } from "../../../../helpers";

export default function ClubButtonDates({
  subscription,
  initialSubscription,
  selected,
  onSelect = (selected) => {},
}) {
  return (
    <div className="vnext-ButtonGroup" style={{ display: "flex" }}>
      {/*<button*/}
      {/*  className={*/}
      {/*    new Date().toISOString().split("T")[0] === subscription.next_charge*/}
      {/*      ? "vnext-ButtonGroup_button active"*/}
      {/*      : "vnext-ButtonGroup_button"*/}
      {/*  }*/}
      {/*  onClick={() => onSelect(new Date())}*/}
      {/*>*/}
      {/*  <strong>{t("subscription_asap")}</strong>*/}
      {/*</button>*/}
      <button
        className={
          subscription.next_charge === initialSubscription.next_charge
            ? "vnext-ButtonGroup_button active"
            : "vnext-ButtonGroup_button"
        }
        onClick={() => onSelect(new Date(initialSubscription.next_charge))}
      >
        <strong>
          {new Date(initialSubscription.next_charge).toLocaleDateString()}
        </strong>
      </button>
      <button
        className={
          new Date().toISOString().split("T")[0] !== subscription.next_charge &&
          subscription.next_charge !== initialSubscription.next_charge
            ? "vnext-ButtonGroup_button two-lines active"
            : "vnext-ButtonGroup_button"
        }
        onClick={() => onSelect(false)}
      >
        <span>
          <strong>{t("subscription_other")}</strong>
        </span>
        {new Date().toISOString().split("T")[0] !== subscription.next_charge &&
          subscription.next_charge !== initialSubscription.next_charge && (
            <strong>
              {new Date(subscription.next_charge).toLocaleDateString()}
            </strong>
          )}
      </button>
    </div>
  );
}

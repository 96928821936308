import { useState, useEffect } from "react";

import axios from "axios";
import { useParams } from "react-router-dom";

async function getClubCollection(country) {
  const data = {
    query: `
      query getCollection($id: ID!) @inContext(country: ${country}) {
          collection(id: $id)  {
          title
          products(first: 50) {
            nodes {
              id
              priceRange {
                maxVariantPrice {
                  amount
                  currencyCode
                }
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
    `,
    operationName: "getCollection",
    variables: { id: "gid://shopify/Collection/291454845093" },
  };

  const options = {
    method: "POST",
    url: "https://cocunat.myshopify.com/api/2023-04/graphql.json",
    headers: {
      "X-Shopify-Storefront-Access-Token": "af2e09134cdde4899eb36a672c8839ad",
      "Content-Type": "application/json",
    },
    data,
  };

  const response = await axios.request(options);
  return response.data.data.collection.products.nodes;
}

const idsWithShopifyIds = [
  { title: "Curly Method", id: 53055, shopifyId: "gid://shopify/Product/8016224387237" },
  { title: "The Absolute", id: 53123, shopifyId: "gid://shopify/Product/8016210133157" },
  { title: "Body Power", id: 53299, shopifyId: "gid://shopify/Product/8018484035749" },
  { title: "The Real C 30 days", id: 152, shopifyId: "gid://shopify/Product/8415207522650" },
  { title: "Boombastic", id: 53183, shopifyId: "gid://shopify/Product/8018473353381" },
  { title: "Wondermask", id: 53102, shopifyId: "gid://shopify/Product/8016198303909" },
  { title: "The Cure", id: 53252, shopifyId: "gid://shopify/Product/8016258990245" },
  { title: "The Extraordinary Oil", id: 52074, shopifyId: "gid://shopify/Product/8016290316453" },
  { title: "Savior", id: 53239, shopifyId: "gid://shopify/Product/8016201973925" },
  { title: "The Architect", id: 53193, shopifyId: "gid://shopify/Product/8016239689893" },
  { title: "All in One", id: 53228, shopifyId: "gid://shopify/Product/8016227991717" },
  { title: "The glow", id: 53176, shopifyId: "gid://shopify/Product/8016289530021" },
  { title: "Extra defined curls", id: 53110, shopifyId: "gid://shopify/Product/8018522538149" }
]

const EURCountries = ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"]

function getClubPrice(countryCode) {
  if (countryCode == "ES") {
    return { price: 25, currency: "EUR", referenceCountry: "ES", symbol: "€", format: 'right' }
  } else if (countryCode == "GB") {
    return { price: 30, currency: "GBP", referenceCountry: "GB", symbol: "£", format: 'left' }
  }

  if (EURCountries.includes(countryCode)) {
    return { price: 30, currency: "EUR", referenceCountry: "ES", symbol: "€", format: 'left' }
  }

  return { price: 40, currency: "USD", referenceCountry: "US", symbol: "$", format: 'left' }
}

export default function useLandingProductPrices() {
  let { country } = useParams();
  const [loading, setLoading] = useState(true)
  const [prices, setPrices] = useState([])

  const clubPrice = getClubPrice(country)

  useEffect(() => {
    fetchCollection()

    async function fetchCollection() {
      try {
        const collection = await getClubCollection(clubPrice.referenceCountry);

        const prices = []
        for (const product of collection) {
          const foundProduct = idsWithShopifyIds.find(p => p.shopifyId === product.id)
          prices.push({
            id: foundProduct.id,
            title: foundProduct.title,
            price: product.priceRange.maxVariantPrice.amount,
            currency: product.priceRange.maxVariantPrice.currencyCode,
            symbol: clubPrice.symbol,
            format: clubPrice.format,
          })
        }

        setPrices(prices)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

  }, [])

  return { loading, prices, clubPrice: getClubPrice(country) }
}

import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "../../css/Club.css";
import { AccountContext } from "../../context/account";
import Button from "../../components/Button";
import { t } from "../../helpers";

const Hero = React.memo(({ onClickCallback }) => {
  const { region } = useContext(AccountContext);

  if (!region) {
    return <div></div>;
  }
  return (
    <>
      {region && (
        <section className="middle-banner-club-landing">
          <div className="title">
            <p className="title-1">
              {t("new_landing_club.middle_banner_title", {
                price: region.extra.club_price,
              })}
            </p>
            <p className="title-2">
              <span className="for">{t("new_landing_club.middle_from")}</span>
              <span className="price">
                60
                <span className="currency-month">
                  {" "}
                  <span class="currency">%</span>
                  {t("new_landing_club.middle_discount")}
                </span>
              </span>
              <p className="ending">
                {t("new_landing_club.middle_all_products")}
              </p>
            </p>
          </div>
          <div className="hero-buttons">
            <Button
              style={{ backgroundColor: "black", width: "100%" }}
              onClick={() => {
                onClickCallback();
              }}
            >
              <span style={{ textTransform: "uppercase" }}>
                {t("new_landing_club.subscribe_club")}
              </span>
            </Button>
          </div>
        </section>
      )}
    </>
  );
});

export default Hero;

import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useLandingProductPrices from "../hooks/useLandingProductPrices";
import ClubProductsInfo from "../ClubProducts";
import Advantages from "../components/club/Advantages";
import ClubProductGallery from "../components/club/ClubProductGallery";
import Faqs from "../components/club/Faqs";
import Hero from "../components/club/Hero";
import Hero2 from "../components/club/Hero2";
import HowTo from "../components/club/HowTo";
import MiddleBanner from "../components/club/MiddleBanner";
import Press from "../components/club/Press";
import Trustpilot from "../components/club/Trustpilot";
import { AccountContext } from "../context/account";
import "../css/Club.css";
import { t } from "../helpers";

function LandingClub() {
  let { lang } = useParams();
  const { region } = useContext(AccountContext);
  const [infoProducts, setInfoProducts] = useState(null);
  const [error, setError] = useState(null);
  const [showMoreProducts, setShowMoreProducts] = useState(true);
  const products_ref = useRef();

  const shopifyPrices = useLandingProductPrices();

  useEffect(() => {
    document.body.classList.add("club-landing-02");

    setInfoProducts(ClubProductsInfo[lang].slice(0, 4));
  }, []);

  const getAllProducts = () => {
    setInfoProducts(ClubProductsInfo[lang]);
    setShowMoreProducts(false);
  };

  const goToProducts = () => {
    products_ref.current.scrollIntoView();
  };

  if (!region) {
    return <div></div>;
  }

  return (
    <>
      <div style={{ background: "white" }} className="landing-club-022023">
        <div className="header">
          <a href="https://www.cocunat.com" className="go-back">
            <img
              src="https://cdn.shopify.com/s/files/1/0619/1693/8405/files/left-arrow.png?v=1685608726"
              alt="Go Back"
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <span className="spansss">{t("new_landing_club.go_back")}</span>
          </a>
          <div className="header-club-logo">
            <img
              src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/logo-new-club-02.png"
              alt="club_1"
              className="logo-img"
            />
          </div>
        </div>

        <div className="landing-club-container" style={{ textAlign: "center" }}>
          <Hero onClickCallback={goToProducts} />
          <Advantages />
        {/* <Hero2 onClickCallback={goToProducts}  /> */}

          {/* PRODUCTS */}
          {infoProducts && (
            <section
              className="club-products"
              style={{ overflow: "hidden" }}
              ref={products_ref}
            >
              <h2>{t("new_landing_club.products_title")}</h2>
              <h2 style={{ fontFamily: "Brown-Regular" }}>
                {t("new_landing_club.products_subtitle", {
                  price: region.extra.club_price,
                })}
              </h2>
              <div style={{ marginTop: "20px" }}>
                {infoProducts.map((info, i) => {
                  return (
                    <ClubProductGallery
                      key={`product-${i}`}
                      shopifyPrices={shopifyPrices}
                      productInfo={info}
                      behaviour={"addToCart"}
                      setError={setError}
                    />
                  );
                })}
                {showMoreProducts && (
                  <a
                    onClick={() => getAllProducts()}
                    className="club-products-link"
                  >
                    {" "}
                    {t("new_landing_club.see_all_products")}
                  </a>
                )}
              </div>
            </section>
          )}
          <MiddleBanner onClickCallback={goToProducts} />
          <HowTo />

          {/* FAQS */}
          <section style={{ backgroundColor: "#f5f5f5" }}>
            <Faqs />
          </section>

          <Press />
          <Trustpilot />
        </div>
      </div>
    </>
  );
}

export default LandingClub;

function Button(props) {
  let type = props.type || "primary";
  let size = props.size || "regular";
  let width = props.width || "width-regular";

  return (
    <>
      <button
        onClick={props.onClick}
        className={`${type} ${size} ${width}`}
        style={props.style}
        data-button-id={props.data}
        id={props.id}
      >
        {props.loading ? (
          <img
            src="https://global.cocunat.com/icons/loading.gif"
            height="20"
            width="20"
            alt="Loading"
          />
        ) : (
          <>{props.children}</>
        )}
      </button>
    </>
  );
}

export default Button;

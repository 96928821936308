import React from "react";
import { Redirect, Route, useParams, useLocation } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = localStorage.getItem("jwt");
  const location = useLocation();

  return (
    <Route
      {...restOfProps}
      render={(props) => {
        const { lang, country } = props.match.params;

        return isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect push to={`/${lang}-${country}/login/${location.search}`} />
        );
      }}
    />
  );
}

export default ProtectedRoute;

import React, { useState, useEffect } from "react";
import { t } from "../helpers";
import Button from "./Button";
import {
  getproductVariations,
  numberOfProductContainers,
  sortVariations,
  variationToOrderline,
} from "../logic/products";

function VariationsSelector({
  productId,
  lang,
  country,
  onChangeVariationsSelection,
  onChangeProductToAdd,
  buttonCallback,
  onChangeLoading,
  setIsPackVariations,
  variationsCached = {},
  updateVariationsCached = () => {},
  defaultSelection = null,
  defaultProductToAdd = null,
}) {
  const [variations, setVariations] = useState([]);
  const [variationsSelection, setVariationsSelection] = useState(null);
  const [productToAdd, setProductToAdd] = useState(null);
  const [isLoadingVariations, setIsLoadingVariations] = useState(true);

  function selectTone(containerId, tone) {
    let _variationsSelection = { ...variationsSelection };
    _variationsSelection[containerId] = variationToOrderline(tone);
    setVariationsSelection(_variationsSelection);
  }

  useEffect(() => {
    fetchVariations(productId);
  }, []);

  useEffect(() => {
    if (variationsSelection) {
      const allSelected = areAllVariationsSelected();
      onChangeVariationsSelection(
        variationsSelection,
        allSelected,
        productToAdd
      );
    }
  }, [variationsSelection]);

  useEffect(() => {
    onChangeLoading(isLoadingVariations);
  }, [isLoadingVariations]);

  useEffect(() => {
    if (productToAdd) {
      onChangeProductToAdd(productToAdd);
    }
  }, [productToAdd]);

  useEffect(() => {
    if (defaultSelection) {
      setVariationsSelection(defaultSelection);
    }
    if (defaultProductToAdd) {
      setProductToAdd(defaultProductToAdd);
    }
  }, [defaultSelection, defaultProductToAdd]);

  const fetchVariations = async (_productId) => {
    try {
      let variationsData = null;
      if (variationsCached.hasOwnProperty(_productId)) {
        variationsData = variationsCached[_productId];
      } else {
        let _variations = await getproductVariations(_productId, lang, country);
        if (_variations.status === 200) {
          variationsData = _variations.data;
          updateVariationsCached(_productId, variationsData);
        }
      }
      if (variationsData) {
        let variationsWithOptions = [];
        let defaultSelectedVariations = defaultSelection
          ? defaultSelection
          : {};

        for (let i = 0; i < variationsData.length; i++) {
          if (
            variationsData[i].is_variation ||
            variationsData[i].is_productcontainer
          ) {
            variationsWithOptions.push(variationsData[i]);
            defaultSelectedVariations[variationsData[i].id] = null;
          } else {
            defaultSelectedVariations[variationsData[i].id] =
              variationToOrderline(variationsData[i]);
          }
        }
        setVariations(sortVariations(variationsWithOptions));
        setIsPackVariations(numberOfProductContainers(variationsData) > 0);
        setVariationsSelection(defaultSelectedVariations);
        setIsLoadingVariations(false);
      }
    } catch (err) {}
  };

  function areAllVariationsSelected() {
    let result = true;
    if (Object.keys(variationsSelection).length > 0) {
      for (const key in variationsSelection) {
        if (!variationsSelection[key]) {
          result = false;
          break;
        }
      }
    } else {
      result = false;
    }

    return result;
  }

  return (
    <section id="selection-variations" style={{ textAlign: "center" }}>
      {variations.map((variation, i) => {
        if (variation.is_productcontainer) {
          return (
            <div key={"container-" + i}>
              <p style={{ marginBottom: 5, fontSize: 16 }}>
                <strong>{variation.translation.name}</strong> -
                {t("selectYourShade")}
              </p>
              {variation.variations.map((variationSingle, x) => (
                <button
                  key={"variation-" + i + "-" + x}
                  onClick={() => {
                    if (!variationSingle.stockout) {
                      selectTone(variation.id, variationSingle);
                    }
                  }}
                  style={{
                    width: "52px",
                    padding: 0,
                    background: "none",
                    margin: "10px 5px",
                    border:
                      variationsSelection &&
                      variationsSelection[variation.id] &&
                      variationsSelection[variation.id].id ===
                        variationSingle.id
                        ? "1px solid #ff5a5f"
                        : "0px",
                  }}
                >
                  <img
                    src={`https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&image=https://global.cocunat.com/photos/${variationSingle.image_variation}`}
                    alt={variationSingle.translation.name}
                    style={{ width: "100%", height: "auto" }}
                  />
                </button>
              ))}
            </div>
          );
        } else {
          return (
            <button
              key={i}
              onClick={() => setProductToAdd(variation)}
              style={{
                width: "52px",
                padding: 0,
                background: "none",
                margin: "10px 5px",
                border:
                  productToAdd && productToAdd.id === variation.id
                    ? "1px solid #ff5a5f"
                    : "0px",
              }}
            >
              <img
                src={`https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&image=https://global.cocunat.com/photos/${variation.image_variation}`}
                alt={variation.translation.name}
                style={{ width: "100%", height: "auto" }}
              />
            </button>
          );
        }
      })}
      {buttonCallback && (
        <>
          {!isLoadingVariations &&
          (areAllVariationsSelected() || productToAdd) ? (
            <Button
              style={{ margin: "30px auto", maxWidth: 455 }}
              type="primary add-to-cart"
              size="big"
              width="wide"
              onClick={buttonCallback}
            >
              <span>{t("select")}</span>
            </Button>
          ) : (
            <h2
              style={{
                margin: "30px auto",
                maxWidth: 455,
              }}
            >
              {t("selectYourShade")}
            </h2>
          )}
        </>
      )}
    </section>
  );
}

export default VariationsSelector;

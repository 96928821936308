import { useContext, useState } from "react";
import RatingStars from "../RatingStars";
import Button from "../Button";
import PriceWithSymbol from "../PriceWithSymbol";
import Price from "../Price";
import { Link, useParams, Redirect } from "react-router-dom";
import { t, currencySymbol } from "../../helpers";
import PromoSticker from "../PromoSticker";
import CONSTANTS from "../../constants";

function ProductLoopClub({
  product,
  featured = false,
  customHandler = null,
  customText = null,
  customWrapper = false,
  overrideClass = null,
  actionFrom = "ProductLoop",
  showButton = true,
}) {
  let { lang, country } = useParams();
  let [redirectTo, setRedirectTo] = useState(null);
  const [isOpenPresaleModal, setIsOpenPresaleModal] = useState(false);

  let url = `https://cocunat.com`;
  let action = "add-to-cart";
  let buttonText = customText || t("add-to-cart");

  const hasSimpleSubscription = (product) => {
    return (
      product.has_subscription &&
      product.prices &&
      product.prices.regular_subscription_price
    );
  };

  if (
    product.is_productcontainer ||
    (product.prices && product.prices.show_gourmet)
  ) {
    action = "view";
    buttonText = t("view_product");
  }

  if (hasSimpleSubscription(product)) {
    action = "view";
    buttonText = customText || t("view_product");
  }

  if (product.stockout) {
    action = "stockout";
    buttonText = t("view_product");
  }

  if (product.presale) {
    action = "presale";
    buttonText = t("product_presale");
  }

  let classes = overrideClass
    ? overrideClass
    : "product-item col-md-3 col-sm-4 col-xs-6";
  if (featured) {
    classes = "product-item featured col-md-4 col-sm-4 col-xs-6";
  }

  if (redirectTo) {
    return <Redirect push to={redirectTo} />;
  }

  function getVariantDiscount(variantId) {
    if (Object.keys(CONSTANTS.UPSELL_DISCOUNT).includes(variantId)) {
      return CONSTANTS.UPSELL_DISCOUNT[variantId];
    }
    return CONSTANTS.UPSELL_DISCOUNT["default"];
  }

  function getProductCartElement() {
    return (
      <>
        <figure style={{ position: "relative" }}>
          {featured ? (
            <img
              src={product.featuredImage.url}
              height="355"
              width="285"
              loading="lazy"
            />
          ) : (
            <img
              src={product.featuredImage.url}
              alt={product.title}
              height="355"
              width="285"
              loading="lazy"
            />
          )}
          {CONSTANTS.LAST_UNITS_PRODUCTS.includes(product.id) ? (
            <div className={`product-tag`}>{t("tags.last-units")}</div>
          ) : (
            CONSTANTS.HIGH_DEMAND_PRODUCTS.includes(product.id) && (
              <div className={`product-tag`}>{t("tags.high-demand")}</div>
            )
          )}
        </figure>
        <div className="product-title">
          <p
            style={{
              fontSize: "13px",
              maxHeight: "100px",
              overflow: "hidden",
            }}
          >
            <strong
              style={{
                fontSize: "16px",
                display: "block",
                textTransform: "uppercase",
              }}
            >
              {product.title}
            </strong>
          </p>

          <p
            style={{
              fontSize: "14px",
              margin: "10px 0px",
              height: 15,
              textAlign: "left",
              display: "flex",
              justifyContent: "space-between",
              /*color: "#767676",*/
              color: "#000",
            }}
          >
            <strong>{t("actualPrice").toUpperCase()}: </strong>
            <strong>
              <span className="price-product">
                <PriceWithSymbol
                  amount={
                    product.default_variant.contextualPricing.price.amount
                  }
                  format={"left"}
                  symbol={currencySymbol(
                    product.default_variant.contextualPricing.price.currencyCode
                  )}
                  spanClassName={"strike-price"}
                />
                <PriceWithSymbol
                  amount={
                    product.default_variant.contextualPricing.price.amount *
                    (1 - getVariantDiscount(product.default_variant.id))
                  }
                  format={"left"}
                  symbol={currencySymbol(
                    product.default_variant.contextualPricing.price.currencyCode
                  )}
                  spanClassName={"actual-price"}
                />
              </span>
            </strong>
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes}>
        {customWrapper ? (
          <div>{getProductCartElement()}</div>
        ) : (
          <Link to={`${url}`}>{getProductCartElement()}</Link>
        )}
        {showButton && (
          <Button
            style={{ backgroundColor: "black" }}
            onClick={() => {
              if (customHandler) {
                customHandler(product);
              } else if (action === "add-to-cart") {
                console.log("addToCartHandler obsolete");
              } else if (action === "presale") {
                setIsOpenPresaleModal(true);
              } else if (action === "stockout") {
              } else {
                setRedirectTo(url);
              }
            }}
          >
            <span>{buttonText}</span>
          </Button>
        )}
      </div>
    </>
  );
}

export default ProductLoopClub;

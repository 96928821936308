import React, { useContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { t, currencySymbol } from "../../../helpers";
import Header from "../../../components/Header";
import GoBackLink from "../../../components/GoBackLink";
import ClubProductsInfo from "../../../ClubProductsInfo";
import Faqs from "../../../components/club/Faqs";
import "../../../css/Club.css";
import ClubProductChoice from "../components/ClubProductChoice";
import Button from "../../../components/Button";
import ClubButtonDates from "../components/ClubButtonDates/";
import CustomCalendar from "../../../components/CustomCalendar/";
import { AccountContext } from "../../../context/account";
import Modal from "../../../components/Modal";
import { formatPrice } from "../../../logic/helpers";
import VariationsSelector from "../../../components/VariationsSelector";

async function getSubscription(id) {
  let response = await fetch(
    `https://rest-api.cocunat.com/v2/subscriptions/${id}/`,
    {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
      },
      mode: "cors", // <---
      cache: "default",
    }
  );
  let data = await response.json();
  return data;
}

async function updateSubscription(id, updatedData) {
  let response = await fetch(
    `https://rest-api.cocunat.com/v2/subscriptions/${id}/`,
    {
      method: "PATCH",
      headers: {
        Authorization: `JWT ${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData), // send the updated data in the request body
      mode: "cors",
      cache: "default",
    }
  );
  let data = await response.json();
  return data;
}

async function updateSubscriptionNextProduct(id, productId) {
  let response = await fetch(
    `https://rest-api.cocunat.com/v2/subscriptions/${id}/next/`,
    {
      method: "POST",
      headers: {
        Authorization: `JWT ${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        next_product_id: productId,
      }), // send the updated data in the request body
      mode: "cors",
      cache: "default",
    }
  );
  let data = await response.json();
  return data;
}

function getClubProductFromLines(language, lines) {
  console.log("getClubProductFromLines");
  console.log(lines);
  console.log(language);

  let clubProduct = null;

  // Assuming lines is an array of lines
  for (let i = 0; i < lines.nodes.length; i++) {
    let line = lines.nodes[i];
    let sku = parseInt(line.sku);

    let productsInLanguage = ClubProductsInfo[language];

    for (let productCategory in productsInLanguage) {
      let categoryProducts = productsInLanguage[productCategory];

      for (let j = 0; j < categoryProducts.length; j++) {
        let product = categoryProducts[j];
        console.log(product.sendingClub + " == " + sku);
        // Comparing line's sku with club product's sendingId
        if (product.sendingClub === sku) {
          clubProduct = line;
          break;
        }
      }

      if (clubProduct) {
        break;
      }
    }

    if (clubProduct) {
      break;
    }
  }

  return clubProduct;
}

function SubscriptionRefill() {
  let { lang, country, type, uuid } = useParams();
  const { isNextProduct, subscriptions, patchSubscription, region } =
    useContext(AccountContext);
  const editSubscription = `/${lang}-${country}/account/profile/subscriptions/${type}/${uuid}`;
  const [infoProducts, setInfoProducts] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);
  const [deliveryChoice, setDeliveryChoice] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [initialSubscription, setInitialSubscription] = useState(null);
  const [price, setPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // Variations
  const [isOpenModalVariationsSelector, setIsOpenModalVariationsSelector] =
    useState(false);
  const [variationsSelection, setVariationsSelection] = useState({});
  const [product, setProduct] = useState(null);
  const [isPackVariations, setIsPackVariations] = useState(true);
  const [subscription, setSubscription] = useState(null);
  const [nextProductsIds, setNextProductsIds] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [selectedNextProduct, setSelectedNextProduct] = useState(null);

  const setNextProduct = async (info) => {
    setLoadingScreen(true);
    let result = await updateSubscriptionNextProduct(uuid, info.sendingClub);
    console.log(result);
    await fetchSubscription();
    setLoadingScreen(false);
  };

  const fetchSubscription = async () => {
    console.log("fetching subscription");
    try {
      const sub = await getSubscription(uuid);
      if (sub) {
        setSubscription(sub);
        let productids = sub.shopify.lines.nodes.map((line) =>
          parseInt(line.sku)
        );
        setNextProductsIds(productids);
      }
    } catch (e) {
      console.log("-------");
      console.log(e);
      console.log(e.message);
      console.log(e.response);
      console.log("-------");
    }
    return;
  };

  const updateCNMUProduct = () => {
    const data = {
      next_product_id: product.id,
      next_product: {
        name: product.name,
        id: product.id,
      },
      packs_variations: null,
    };
    const packs_variations = Object.values(variationsSelection);
    if (
      isPackVariations &&
      packs_variations &&
      packs_variations.some((e) => e)
    ) {
      data["packs_variations"] = { [product.id.toString()]: packs_variations };
    }
    updateSubscription(uuid, data);
  };

  useEffect(() => {
    fetchSubscription();
  }, []);

  useEffect(() => {
    if (subscription) {
      let clubProduct = getClubProductFromLines(
        subscription.language,
        subscription.shopify.lines
      );
      setSelectedNextProduct(clubProduct);
    }
  }, [subscription]);

  const goToUpsells = () => {
    setRedirectTo(`${editSubscription}/upsells`);
  };

  if (!infoProducts) {
    setInfoProducts(ClubProductsInfo[lang]);
  }

  const updateDeliveryChoice = async (date) => {
    setDeliveryChoice(date);
    if (date === false) {
      setIsOpenModal(true);
      return;
    }

    setLoadingScreen(true);
    try {
      let result = await updateSubscription(uuid, {
        next_charge: date.toISOString().split("T")[0],
      });
      console.log("result", result);
      if (!result) {
        alert("Error updating subscription");
      }
    } catch (err) {
      setLoadingScreen(false);
      alert("Error updating subscription");
    }
    setLoadingScreen(false);
  };

  const saveSubscription = async () => {
    setIsLoading(true);
    await patchSubscription(uuid);
    setIsLoading(false);
    setRedirectTo(`${editSubscription}?success=true`);
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <Header />
      <Modal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        wrapperStyle={{ padding: "0px" }}
        containerStyle={{ padding: "0px", width: "auto", borderRadius: 6 }}
        contentStyle={{ padding: "0px", borderRadius: 6 }}
      >
        {subscription && (
          <CustomCalendar
            dayLabels={[
              t("day_1"),
              t("day_2"),
              t("day_3"),
              t("day_4"),
              t("day_5"),
              t("day_6"),
              t("day_7"),
            ]}
            monthLabels={[
              t(`month_0`),
              t(`month_1`),
              t(`month_2`),
              t(`month_3`),
              t(`month_4`),
              t(`month_5`),
              t(`month_6`),
              t(`month_7`),
              t(`month_8`),
              t(`month_9`),
              t(`month_10`),
              t(`month_11`),
            ]}
            disableDates={(date) => {
              let maxDays = new Date(subscription.next_charge);
              maxDays.setDate(maxDays.getDate() + 30);
              return date < new Date().getTime() || date > maxDays.getTime();
            }}
            onChange={async (newDate, endDate) => {
              console.log(
                new Date(newDate).toISOString().split("T")[0],
                new Date(endDate).toISOString().split("T")[0]
              );
              setLoadingScreen(true);
              try {
                await updateSubscription(uuid, {
                  next_charge: new Date(newDate).toISOString().split("T")[0],
                });
                await fetchSubscription(uuid);
                setLoadingScreen(false);
              } catch (e) {
                setLoadingScreen(false);
                alert("Error");
              }
              setIsOpenModal(false);
            }}
            startDate={new Date(subscription.next_charge).getTime()}
            range={false}
          />
        )}
      </Modal>
      {loadingScreen && (
        <div
          id="club-loading-screen"
          style={{
            height: "100vh",
            width: "100%",
            background: "#fff",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999999,
            top: 0,
            left: 0,
          }}
        >
          <img
            src="https://global.cocunat.com/icons/loading.gif"
            height="70"
            width="70"
            alt="Loading"
          />
        </div>
      )}
      <div className="container accountRefill">
        <div style={{ paddingLeft: 16 }}>
          {subscription && (
            <GoBackLink to={editSubscription} title={t("go_to_edit_club")} />
          )}
        </div>
        <strong
          style={{
            display: "block",
            borderTop: "1px solid #EFEFEF",
            borderBottom: "1px solid #EFEFEF",
            fontSize: "16px",
            padding: 11,
            textAlign: "center",
          }}
        >
          {t("sub_manage_shipment")}
        </strong>
        <div style={{ display: "none" }}>
          <h1>Translations</h1>
          <p>{t("go_to_edit_club")}</p>
          <p>{t("sub_go_shipment")}</p>
          <p>{t("sub_already_sent_product")}</p>
          <p>{t("sub_continue")}</p>
          <p>{t("sub_upsell_title")}</p>
          <p>{t("sub_upsell_discount")}</p>
          <p>{t("sub_upsell_shipping")}</p>
          <p>{t("sub_next_without_product")}</p>
          <p>{t("sub_free_shipping_adding")}</p>
        </div>
        {/* PRODUCTS */}
        <p style={{ margin: "28px 15px 15px 15px" }}>
          <strong>{t("sub_select_shipment_date")}</strong>
        </p>
        {subscription && (
          <ClubButtonDates
            initialSubscription={subscription}
            subscription={subscription}
            onSelect={updateDeliveryChoice}
            selected={deliveryChoice}
          />
        )}
        <p style={{ margin: "15px" }}>
          <strong>{t("sub_select_next_product")}</strong>
        </p>
        <section className="Clubproduct container-wide">
          {product && (
            <Modal
              isOpen={isOpenModalVariationsSelector}
              setIsOpen={setIsOpenModalVariationsSelector}
            >
              <VariationsSelector
                productId={product.id}
                onChangeVariationsSelection={(
                  _variationsSelection,
                  _allSelected
                ) => {
                  setVariationsSelection(_variationsSelection);
                }}
                onChangeProductToAdd={(product) => {
                  setProduct({
                    id: product.id,
                    name: product.translation.name,
                  });
                }}
                buttonCallback={() => {
                  setIsOpenModalVariationsSelector(false);
                  updateCNMUProduct();
                }}
                onChangeLoading={() => {}}
                setIsPackVariations={setIsPackVariations}
                lang={lang}
                country={country}
                // variationsCached={variationsCached}
                // updateVariationsCached={updateVariationsCached}
              />
            </Modal>
          )}

          {/* FACE */}
          <div className="productInner">
            <p className="title">
              <span>{t("face")}</span>
            </p>
            {infoProducts && infoProducts.face && (
              <>
                <div className="refillProductClud">
                  {infoProducts.face.map((info, i) => {
                    return (
                      <>
                        {info.sendingClub && (
                          <ClubProductChoice
                            key={i}
                            info={info}
                            onClick={() => setNextProduct(info)}
                            isNextProduct={nextProductsIds.includes(
                              info.sendingClub
                            )}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          {/* BODY */}
          <div className="productInner">
            <p className="title">
              <span>{t("body")}</span>
            </p>
            {infoProducts && infoProducts.body && (
              <>
                <div className="refillProductClud">
                  {infoProducts.body.map((info, i) => {
                    return (
                      <>
                        {info.sendingClub && (
                          <ClubProductChoice
                            info={info}
                            onClick={() => setNextProduct(info)}
                            isNextProduct={nextProductsIds.includes(
                              info.sendingClub
                            )}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          {/* HAIR */}
          <div className="productInner">
            <p className="title">
              <span>{t("hair")}</span>
            </p>
            {infoProducts && infoProducts.hair && (
              <>
                <div className="refillProductClud">
                  {infoProducts.hair.map((info, i) => {
                    return (
                      <>
                        {info.sendingClub && (
                          <ClubProductChoice
                            info={info}
                            onClick={() => setNextProduct(info)}
                            isNextProduct={nextProductsIds.includes(
                              info.sendingClub
                            )}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </>
            )}
          </div>
         {/* CNMU
          <div className="productInner">
            <p className="title">
              <span>C.NOMAKEUP</span>
            </p>
            {infoProducts && infoProducts.cnmu && (
              <div className="refillProductClud">
                {infoProducts.cnmu.map((info, i) => {
                  return (
                    <>
                      {info.sendingClub && (
                        <ClubProductChoice
                          info={info}
                          onClick={() => {
                            setProduct({
                              id: info.sendingClub,
                              name: info.title,
                            });
                            setIsOpenModalVariationsSelector(true);
                          }}
                          isNextProduct={nextProductsIds.includes(
                            info.sendingClub
                          )}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            )}
          </div>
          */}
        </section>
        <Faqs region={region} />

        {subscription && subscription.shopify && selectedNextProduct && (
          <section id="floating-bottom">
            <Button
              size="big"
              width="wide"
              loading={isLoading}
              onClick={goToUpsells}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: 13,
              }}
            >
              <span style={{ maxWidth: 170, display: "block" }}>
                {selectedNextProduct?.title}{" "}
                {currencySymbol(
                  selectedNextProduct?.currentPrice?.currencyCode
                )}
                {parseFloat(selectedNextProduct?.currentPrice?.amount).toFixed(
                  2
                )}
              </span>
              <span>{t("sub_continue")}</span>
            </Button>
            <small>{t("sub_footer_club", { price: price })}</small>
          </section>
        )}
      </div>
    </>
  );
}

export default SubscriptionRefill;

import React, { useState, useEffect, useContext } from "react";
import { t } from "../../helpers";
import Header from "../../components/Header";
import { Redirect, useParams } from "react-router-dom";
import {
  getAccountSubscriptionSingle,
  saveSubscription,
  saveAddress,
  getProvincesFromCountryCode,
} from "../../logic/account";
import HowItWorks from "../../components/club/HowItWorks";
import Faqs from "../../components/club/Faqs";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import constants from "../../constants";
import InputGroup from "../../components/InputGroup";
import { AccountContext } from "../../context/account";
import { getURLParams } from "../../helpers";
import SimpleToast from "../../components/SimpleToast";

async function getSubscription(id) {
  let response = await fetch(
    `https://rest-api.cocunat.com/v2/subscriptions/${id}/`,
    {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
      },
      mode: "cors", // <---
      cache: "default",
    }
  );
  let data = await response.json();
  return data;
}

async function getEmailLinkPaymentMethod(id) {
  let response = await fetch(
    `https://rest-api.cocunat.com/v2/subscriptions/${id}/payment-method/change/`,
    {
      method: "POST",
      headers: {
        Authorization: `JWT ${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({}),
      mode: "cors", // <---
      cache: "default",
    }
  );
  let data = await response.json();
  return data;
}

async function cancelSubscriptionAPI(id, reason = {}) {
  let response = await fetch(
    `https://rest-api.cocunat.com/v2/subscriptions/${id}/cancel/`,
    {
      method: "POST",
      headers: {
        Authorization: `JWT ${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reason),
      mode: "cors", // <---
      cache: "default",
    }
  );
  let data = await response.json();
  return data;
}

async function updateSubscription(id, updatedData) {
  let response = await fetch(
    `https://rest-api.cocunat.com/v2/subscriptions/${id}/`,
    {
      method: "PATCH",
      headers: {
        Authorization: `JWT ${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData), // send the updated data in the request body
      mode: "cors",
      cache: "default",
    }
  );
  let data = await response.json();
  return data;
}

function CancelSubscriptionForm({ subscription, cancelSubscription }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [showTextArea, setShowTextArea] = React.useState(false);

  async function onSubmit(e) {
    e.preventDefault();

    const reason = e.target?.reason?.value;
    const reasonDetails = e.target?.reason_details?.value;

    await cancelSubscription({ reason, reason_details: reasonDetails });
    setIsOpen(false);
  }

  return (
    <>
      <p>
        {subscription.subscription_type &&
          subscription.subscription_type.includes("GOURMET") && (
            <strong>{t("sub_not_convicide")} </strong>
          )}
        {t("unsubscribe_whenever")}
      </p>
      <a onClick={() => setIsOpen(true)} style={{ cursor: "pointer" }}>
        {t("cancel_subscription")}
      </a>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <form onSubmit={onSubmit}>
          <div style={{ margin: "0 auto" }}>
            <div style={{ textAlign: "left" }}>
              <h3>{t("cancel_subscription_title")}</h3>
              <p>{t("cancel_subscription_text")}</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                gap: "10px",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <input
                  required
                  type="radio"
                  name="reason"
                  value="excess_product"
                  id="excess_product"
                />
                <label htmlFor="excess_product">
                  {t("cancel_subscription_reason_excess_product")}
                </label>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <input
                  type="radio"
                  name="reason"
                  value="more_variety_wanted"
                  id="more_variety_wanted"
                />
                <label htmlFor="more_variety_wanted">
                  {t("cancel_subscription_reason_more_variety_wanted")}
                </label>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <input
                  type="radio"
                  name="reason"
                  value="personal_changes"
                  id="personal_changes"
                />
                <label htmlFor="personal_changes">
                  {t("cancel_subscription_reason_personal_changes")}
                </label>
              </div>

              <div style={{ display: "flex", gap: "10px" }}>
                <input
                  type="radio"
                  name="reason"
                  value="other"
                  id="other"
                  onChange={() => setShowTextArea(true)}
                />
                <label htmlFor="other">
                  {t("cancel_subscription_reason_other")}
                </label>
              </div>
              {showTextArea && (
                <textarea
                  required
                  name="reason_details"
                  cols="30"
                  rows="4"
                  placeholder={t(
                    "cancel_subscription_reason_other_placeholder"
                  )}
                  style={{ lineHeight: "25px" }}
                ></textarea>
              )}
            </div>
            <div style={{ marginTop: "2rem" }}>
              <Button>{t("cancel_subscription")}</Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

function AccountSubscriptionSingle() {
  const { subscriptions } = useContext(AccountContext);
  let { lang, country, type, uuid } = useParams();
  let [subscription, setSubscription] = useState(null);
  const [address, setAddress] = useState(null);
  const [addressError, setAddressError] = useState(null);

  const [redirectTo, setRedirectTo] = useState(null);
  const [isOpenModalAddress, setIsOpenModalAddress] = useState(false);
  const [isOpenModalPaymentMethod, setIsOpenModalPaymentMethod] =
    useState(false);
  const [isLoadingEmailSent, setIsLoadingEmailSent] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [loadingNextProduct, setLoadingNextProduct] = useState(false);
  const [nextProduct, setNextProduct] = useState(null);
  const [provinces, setProvinces] = useState(null);

  useEffect(() => {
    if (subscriptions && subscriptions[uuid]) {
      setSubscription(subscriptions[uuid]);
      setAddress(subscriptions[uuid].address);
      let successParam = getURLParams(location.search).success;
      if (successParam) {
        setShowMessage(successParam);
      }
    }
  }, [subscriptions]);

  const changePaymentMethod = async () => {
    setIsOpenModalPaymentMethod(true);
    setIsLoadingEmailSent(true);
    const response = await getEmailLinkPaymentMethod(uuid);
    if (response.customer_id) {
      setIsLoadingEmailSent(false);
    } else {
      alert(t("error_send_email_link_payment_method"));
      setIsOpenModalPaymentMethod(false);
    }
  };

  async function fetchSubscription() {
    console.log("fetching subscription");
    try {
      const sub = await getSubscription(uuid);
      if (sub) {
        console.log(sub);
        setSubscription(sub);
        setAddress(sub.address);

        const provinces = await getProvincesFromCountryCode(
          sub.address.country_code
        );
        setProvinces(provinces);
      }
    } catch (e) {
      console.log("-------");
      console.log(e);
      console.log(e.message);
      console.log(e.response);
      console.log("-------");
    }
  }

  useEffect(() => {
    fetchSubscription();
  }, []);

  async function changeSubscriptionAddress(e) {
    let newAddress;
    if (newAddress === null) {
      newAddress = {};
    } else {
      newAddress = { ...address };
    }

    newAddress.name = newAddress.first_name + " " + newAddress.last_name;

    if (e.target.name === "province") {
      const provinces = await getProvincesFromCountryCode(address.country_code);
      const foundProvince = provinces.find((p) => p.name === e.target.value);
      newAddress.province_code = foundProvince.code;
    }

    newAddress[e.target.name] = e.target.value;
    setAddress(newAddress);
  }

  async function cancelSubscription(reason) {
    await cancelSubscriptionAPI(uuid, reason);
    window.location.reload(true);
  }

  async function reactivateSubscription() {
    await updateSubscription(uuid, {
      requested_cancel: false,
    });
    window.location.reload(true);
  }

  async function saveSubscriptionAddress() {
    try {
      let cleanedAddress = { ...address };
      delete cleanedAddress.country;
      const response = await saveAddress(subscription.id, address);
      setAddress(response.data);
      setAddressError(null);
      setIsOpenModalAddress(false);
    } catch {
      setAddressError(t("error_while_saving_address"));
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <Header />
      {subscription &&
        "success" in subscription &&
        showMessage &&
        subscription.success && (
          <SimpleToast
            onClose={() => setShowMessage(false)}
            title={t("sub_success_title")}
            message={t("sub_success_text", {
              date: new Date(subscription.next_charge).toLocaleDateString(),
            })}
            success={true}
          />
        )}
      <p
        style={{
          backgroundImage:
            "url('https://global.cocunat.com/photos/hero-65.jpg')",
          height: "80px",
          textAlign: "center",
          backgroundSize: "cover",
          lineHeight: "80px",
          fontWeight: "bold",
          fontFamily: "Brown-Bold",
          fontSize: "16px",
          margin: "0px",
        }}
      >
        {t("my_subscriptions")}
      </p>
      <Modal
        setIsOpen={setIsOpenModalPaymentMethod}
        isOpen={isOpenModalPaymentMethod}
      >
        {subscription && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoadingEmailSent ? (
              <img
                src="https://global.cocunat.com/icons/loading.gif"
                style={{ height: 50 }}
              />
            ) : (
              <p>
                {t("send_email_link_payment_method_success", {
                  email: subscription.email,
                })}
              </p>
            )}
          </div>
        )}
      </Modal>
      <Modal
        setIsOpen={(open) => {
          setAddressError(null);
          setIsOpenModalAddress(open);
        }}
        isOpen={isOpenModalAddress}
      >
        {subscription && (
          <>
            <div className="row" style={{ marginTop: 20 }}>
              <div className="col-xs-6" style={{ paddingRight: 5 }}>
                <InputGroup
                  id="name"
                  name="first_name"
                  title={t("name")}
                  value={address?.first_name ?? ""}
                  onChange={changeSubscriptionAddress}
                />
              </div>
              <div className="col-xs-6" style={{ paddingLeft: 5 }}>
                <InputGroup
                  id="last_name"
                  name="last_name"
                  title={t("last_name")}
                  value={address?.last_name ?? ""}
                  onChange={changeSubscriptionAddress}
                />
              </div>
            </div>
            <InputGroup
              id="address"
              name="address1"
              title={t("address")}
              value={address?.address1 ?? ""}
              onChange={changeSubscriptionAddress}
            />
            <div className="row">
              <div className="col-xs-6" style={{ paddingRight: 5 }}>
                <InputGroup
                  id="zip_code"
                  name="zip"
                  title={t("zip_code")}
                  value={address?.zip ?? ""}
                  onChange={changeSubscriptionAddress}
                />
              </div>
              <div className="col-xs-6" style={{ paddingLeft: 5 }}>
                <InputGroup
                  id="city"
                  name="city"
                  title={t("city")}
                  value={address?.city ?? ""}
                  onChange={changeSubscriptionAddress}
                />
              </div>
            </div>
            {provinces && (
              <div className="row">
                <div className="col-xs-12">
                  <div className="input-group">
                    <select
                      name="province"
                      style={{ borderRadius: "4px" }}
                      defaultValue={address?.province ?? ""}
                      onChange={changeSubscriptionAddress}
                    >
                      {provinces.map((province) => (
                        <option key={province.code} value={province.name}>
                          {province.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-xs-4" style={{ paddingRight: 0 }}>
                <div className="input-group">
                  <select
                    id="prefix_phone"
                    name="prefix_phone"
                    value={address?.prefix_phone ?? ""}
                    onChange={changeSubscriptionAddress}
                    style={{
                      borderRadius: "4px 0px 0px 4px",
                      borderRightWidth: 0,
                    }}
                  >
                    {(() => {
                      const { COUNTRIES } = constants;
                      const predefinedOrder = Array.from(
                        new Set([
                          address?.country_code,
                          "IT",
                          "FR",
                          "ES",
                          "GB",
                          "US",
                          "DE",
                        ])
                      );

                      const sortedCountries = COUNTRIES.sort((a, b) => {
                        let indexA = predefinedOrder.indexOf(a.code);
                        let indexB = predefinedOrder.indexOf(b.code);

                        if (indexA !== -1 || indexB !== -1) {
                          return indexA === -1
                            ? 1
                            : indexB === -1
                            ? -1
                            : indexA - indexB;
                        }
                        return a.name > b.name ? 1 : -1;
                      });

                      return sortedCountries.map((country, i) => {
                        return (
                          <option key={i} value={country.prefix}>
                            {country.prefix} ({country.name})
                          </option>
                        );
                      });
                    })()}
                  </select>
                </div>
              </div>
              <div className="col-xs-8" style={{ paddingLeft: 0 }}>
                <InputGroup
                  id="phone"
                  name="phone"
                  title={t("phone")}
                  disabled={true}
                  value={address?.phone ?? ""}
                  onChange={changeSubscriptionAddress}
                  style={{ borderRadius: "0px 4px 4px 0px" }}
                />
              </div>
            </div>
          </>
        )}
        <Button
          size="big"
          type="black"
          width="wide"
          onClick={() => saveSubscriptionAddress()}
          style={{ marginTop: 30 }}
        >
          <span>{t("change_address")}</span>
        </Button>
        {addressError && (
          <div className="alert alert-danger">⚠ {addressError}</div>
        )}
      </Modal>
      <div className="container">
        <div id="subscriptions" style={{ paddingTop: 45 }}>
          {subscription && (
            <>
              <div className="row">
                <div
                  className="col-xs-12 col-sm-12 col-md-6"
                  style={{
                    textAlign: "left",
                    marginBottom: 63,
                  }}
                >
                  <h3>CLUB COCUNAT</h3>

                  {subscription.status === "active" && (
                    <>
                      <p>
                        <strong>{t("subscription_next")}: </strong>
                        {new Date(
                          subscription.next_charge
                        ).toLocaleDateString()}
                      </p>
                      <p style={{ marginTop: 0 }}>
                        <strong>{t("subscription_next_product")}: </strong>
                        {subscription &&
                          subscription.shopify &&
                          subscription.shopify.lines &&
                          subscription.shopify.lines.nodes &&
                          subscription.shopify.lines.nodes.map(
                            (orderline, i) => {
                              return (
                                <span key={i}>
                                  <br />- {orderline.title}
                                </span>
                              );
                            }
                          )}
                      </p>
                      <Button
                        size="big"
                        type="transparent"
                        width="wide"
                        onClick={() =>
                          setRedirectTo(
                            `/${lang}-${country}/account/profile/subscriptions/${subscription.subscription}/${subscription.id}/refill`
                          )
                        }
                      >
                        <span>{t("sub_change_shipment")}</span>
                      </Button>
                    </>
                  )}
                  {subscription.status === "finished" ? (
                    <>
                      <p> {t("subscription_finished")}</p>
                    </>
                  ) : (
                    (subscription.status === "cancelled" ||
                      subscription.status === "stopped") && (
                      <>
                        <p> {t("subscription_cancelled")}</p>
                      </>
                    )
                  )}
                </div>
                <div
                  className="col-xs-12 col-sm-12 col-md-6"
                  style={{
                    textAlign: "left",
                    marginBottom: 63,
                  }}
                >
                  <h3>{t("address_title")}</h3>
                  {address && (
                    <p style={{ marginBottom: 28 }}>
                      {address.first_name} {address.last_name}
                      <br />
                      {address.address1}, {address.zip}, {address.city},{" "}
                      {address.country_code}
                    </p>
                  )}
                  <Button
                    size="big"
                    type="transparent"
                    width="wide"
                    onClick={() => setIsOpenModalAddress(true)}
                  >
                    <span>{t("change_address")}</span>
                  </Button>
                </div>
              </div>
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="col-xs-12 col-sm-12 col-md-6"
                  style={{
                    textAlign: "left",
                    marginBottom: 63,
                  }}
                >
                  <h3>{t("change_payment_method")}</h3>
                  <Button
                    size="big"
                    type="transparent"
                    width="wide"
                    onClick={() => changePaymentMethod()}
                  >
                    <span>{t("change_payment_method")}</span>
                  </Button>
                </div>
              </div>
              {subscription.status === "active" && (
                <section
                  style={{ margin: "0px auto 50px auto", maxWidth: "250px" }}
                >
                  {(() => {
                    const requestedCancel = subscription.requested_cancel;
                    const isActive = subscription.status === "active";
                    const isGourmetSubsType =
                      subscription.subscription_type &&
                      subscription.subscription_type.includes("GOURMET");

                    if (!isActive) {
                      return (
                        <p>
                          <strong style={{ textTransform: "lowercase" }}>
                            {t("subscription") +
                              " " +
                              t("subscription_cancelled")}
                          </strong>
                        </p>
                      );
                    }

                    if (!requestedCancel) {
                      return (
                        <CancelSubscriptionForm
                          subscription={subscription}
                          cancelSubscription={cancelSubscription}
                        />
                      );
                    }

                    return (
                      <>
                        <p>
                          {isGourmetSubsType && (
                            <strong>{t("sub_not_convicide")} </strong>
                          )}
                          {t("subscription_cancellation_requested")}
                        </p>
                        <a
                          onClick={() => reactivateSubscription()}
                          style={{ cursor: "pointer" }}
                        >
                          {t("reactivate_subscription")}
                        </a>
                      </>
                    );
                  })()}
                </section>
              )}
              <div className="row">
                <Faqs />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AccountSubscriptionSingle;

import axios from "axios";
import constants from "../constants";
import { removeQuotes } from "./helpers";

function getUrlPromo(promo) {
  return `&promo=${promo}`;
}

export async function getAllProductsGroupedByCategory(
  lang,
  country,
  promo = ""
) {
  let params = {
    lang: lang,
    country: country,
  };
  if (promo !== "") {
    params.promo = promo;
  }
  return await axios.get(`${constants.PRODUCTS_API_BASE_URL}categories/all/`, {
    params,
  });
}

export async function addProductReview(productId, review) {
  return axios.post(
    `https://cocunatapi.net/products-new/products/${productId}/reviews`,
    review
  );
}

export async function getProductComments(productIdsList, lang) {
  return axios.get(
    `https://proxy.cocunat.com/products-new/reviews-batch/?products=${productIdsList}&lang=${lang}&approved=true`
  );
}

export async function getProductCommentsOriginalVersion(
  productIdsList,
  lang,
  preview = false
) {
  return axios.get(
    `https://proxy.cocunat.com/products-new/reviews-batch/?products=${productIdsList}&translation=${lang}&approved=true&preview=${preview}`
  );
}

export async function getproductVariations(id, lang, country, promo = "") {
  let params = {
    lang: lang,
    country: country,
  };
  if (promo !== "") {
    params.promo = promo;
  }
  return await axios.get(
    `${constants.PRODUCTS_API_BASE_URL}${id}/variations/`,
    {
      params,
    }
  );
}

// return await axios.get(`https://reviews-production.cocunat.workers.dev/comments/?language=${lang}&product_id=${productId}`)

export async function getProductsPrices(productIds, country, promo = "") {
  let params = {
    country: country,
    ids: productIds.toString(),
  };
  if (promo !== "") {
    params.promo = promo;
  }
  console.log(params);
  return await axios.get(constants.PRODUCTS_API_BASE_URL, {
    params,
  });
}

export async function getProductUpsells(id, lang, country, promo = "") {
  let params = {
    lang: lang,
    country: country,
  };
  if (promo !== "") {
    params.promo = promo;
  }
  return await axios.get(`${constants.PRODUCTS_API_BASE_URL}${id}/upsells`, {
    params,
  });
}

export async function getProductPacksInner(id, lang, country, promo = "") {
  let params = {
    lang: lang,
    country: country,
  };
  if (promo !== "") {
    params.promo = promo;
  }
  return await axios.get(`${constants.PRODUCTS_API_BASE_URL}${id}/pack`, {
    params,
  });
}

export async function getProductById(id, lang, country, promo = "") {
  let params = {
    lang: lang,
    country: country,
  };
  if (promo !== "") {
    params.promo = promo;
  }
  return await axios.get(`${constants.PRODUCTS_API_BASE_URL}${id}`, {
    params,
  });
}

export async function getProductsByCategory(
  categoryId,
  lang,
  country,
  promo = ""
) {
  let params = {
    lang: lang,
    country: country,
  };
  if (promo !== "") {
    params.promo = promo;
  }
  return await axios.get(
    `${constants.PRODUCTS_API_BASE_URL}category/${categoryId}`,
    { params }
  );
}

export async function searchProducts(query, lang, country, promo = "") {
  let params = {
    q: query,
    lang: lang,
    country: country,
  };
  if (promo !== "") {
    params.promo = promo;
  }
  return await axios.get(`${constants.PRODUCTS_API_BASE_URL}search?`, {
    params,
  });
}

export async function getProductBySlug(slug, lang, country, promo = "") {
  let params = {
    lang: lang,
    country: country,
  };

  if (promo !== "") {
    params.promo = promo;
  }
  return await axios.get(`${constants.PRODUCTS_API_BASE_URL}${slug}`, {
    params,
  });
}

export function getProductSkeletons(number) {
  let skeletons = [];
  for (let i = 0; i < number; i++) {
    skeletons.push(constants.SKELETON_PRODUCT);
  }
  return skeletons;
}

export function numberOfProductContainers(variations) {
  let containers = 0;
  for (let i = 0; i < variations.length; i++) {
    if (variations[i].is_productcontainer) {
      containers++;
    }
  }
  return containers;
}

export function sortVariations(variationsData) {
  variationsData.sort((a, b) => {
    try {
      if (a.meta.order > b.meta.order) return 1;
      if (a.meta.order < b.meta.order) return -1;
    } catch {}
    return 0;
  });

  for (let i = 0; i < variationsData.length; i++) {
    if (
      variationsData[i].variations &&
      variationsData[i].variations.length > 0
    ) {
      variationsData[i].variations = variationsData[i].variations.sort(
        (a, b) => {
          try {
            if (a.meta.order > b.meta.order) return 1;
            if (a.meta.order < b.meta.order) return -1;
          } catch {}
          return 0;
        }
      );
    }
  }

  return variationsData;
}

/**
 * POST https://rest-api.cocunat.com/v2/reservations/
 * {
 *   "product":46,
 *   "email":"asassasasa@cocunat.com",
 *   "language":"es",
 *   "country":"ES"
 * }
 */
export function productReservation(productId, email, lang, country) {
  return axios.post(constants.API_BASE_URL + "reservations/", {
    product: productId,
    email: email,
    language: lang,
    country: country,
  });
}

export function variationToOrderline(variation) {
  return {
    id: variation.id,
    number: 1,
    price: 0,
    name: variation.translation.name,
    image: variation.image_variation || variation.image,
    translations: {
      name: variation.translation.name,
    },
  };
}

export async function getProductVariationsForCart(
  id,
  lang,
  country,
  promo = "",
  variations = ""
) {
  let params = {
    lang: lang,
    country: country,
    promo: promo,
    variation_ids: variations,
  };
  if (promo !== "") {
    params.promo = promo;
  }
  return await axios.get(
    `${constants.PRODUCTS_API_BASE_URL}/${id}/cart-variations/`,
    {
      params,
    }
  );
}
export async function getProductByIds(ids, lang, country, promo = "") {
  let params = {
    lang: lang,
    country: country,
    ids: ids,
  };

  if (promo !== "") {
    params.promo = promo;
  }
  return await axios.get(`${constants.PRODUCTS_API_BASE_URL}`, {
    params,
  });
}

export function getSchemaInfo(product, lang, country, cart) {
  if (!product || !lang || !country || !cart) {
    return {};
  }
  return JSON.stringify({
    "@context": "http://schema.org",
    "@type": "Product",
    name: product.translation.name,
    description: removeQuotes(product.translation.meta_description),
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: parseFloat(product.rating),
      reviewCount: product.comments_summary ? product.comments_summary.num : 0,
    },
    productID: product.id,
    image: `https://global.cocunat.com/photos/${product.image}`,
    url: `https://cocunat.com/${lang}-${country.toUpperCase()}/product/${
      product.translation.slug
    }`,
    keywords: product.translation.meta_keywords,
    offers: {
      "@type": "Offer",
      availability: "https://schema.org/InStock",
      price: product.prices.price,
      priceCurrency: cart.currency,
    },
  });
}

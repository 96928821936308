function ratingStars(rating, color = null) {
  let stars = "";
  let fullStars = parseInt(rating);
  let halfStars =
    0 < Math.round(rating) - Number(rating.toFixed(2)) &&
    Math.round(rating) - Number(rating.toFixed(2)) < 0.5
      ? 1
      : 0;
  let emptyStars = 5 - fullStars - halfStars;
  let _color = color || "#767676";

  Array.from(
    {
      length: fullStars,
    },
    (_, i) => {
      stars += `<span style="color=${_color}">★</span>`;
    }
  );

  Array.from(
    {
      length: halfStars,
    },
    (_, i) => {
      stars += `<span class="halfStar">★</span>`;
    }
  );

  Array.from(
    {
      length: emptyStars,
    },
    (_, i) => {
      stars += `<span style="color:#e0e0e0" >★</span>`;
    }
  );

  return stars;
}

function RatingStars({ rating, size = 19 }) {
  rating = parseFloat(rating);
  return (
    <span
      style={{ fontSize: `${size}px` }}
      dangerouslySetInnerHTML={{
        __html: `${ratingStars(rating)}`,
      }}
    />
  );
}

export default RatingStars;

function InputGroup({
  id,
  title,
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  type,
  showLabel = false,
  description,
  style = null,
}) {
  return (
    <div className="input-group component" style={style}>
      {showLabel && <label htmlFor={id}>{title}: </label>}
      <input
        type={type}
        name={name}
        id={id}
        placeholder={title}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {description && <small>{description}</small>}
    </div>
  );
}

export default InputGroup;

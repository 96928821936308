import React, { useContext, useState, useEffect } from "react";
import Button from "../Button";
import PriceWithSymbol from "../PriceWithSymbol";
import { useParams, Redirect } from "react-router-dom";
import { t, buildPhotoUrl } from "../../helpers";
import AddClubModal from "../club/AddClubModal";
import { getProductById } from "../../logic/products";
import SelectToneModal from "./SelectToneModal";
import Client from "shopify-buy";
import { cartCreate, getCheckoutUrl } from "../../logic/shopify";
import { AccountContext } from "../../context/account";
import RatingStars from "../RatingStars";

const ClubProductGallery = React.memo(
  ({
    productInfo,
    shopifyPrices,
    behaviour = null,
    selectProduct = null,
    setError = () => {},
  }) => {
    const { region } = useContext(AccountContext);
    let { lang, country } = useParams();
    let [redirectTo, setRedirectTo] = useState(null);
    const [isOpenAddClubModal, setIsOpenAddClubModal] = useState(false);
    const [isOpenSelectToneModal, setIsOpenSelectToneModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [product, setProduct] = useState(null);

    useEffect(() => {
      console.log("productInfo", productInfo);
      if (!product && !shopifyPrices.loading) {
        fetchProductData();
      }
    }, [productInfo, shopifyPrices.loading]);

    const fetchProductData = async () => {
      let countryToFetch = region.use_world ? "XX" : country;
      // TODO: getProductById calls to a legacy microservice that is currently useless.
      // The following call should be removed, but some things are still consuming legacy properties from
      // this _product object.
      let _product = await getProductById(
        productInfo.id,
        lang,
        countryToFetch,
        ""
      );
      let data = _product.data;
      data.sendingClub = productInfo.sendingClub;

      delete data.prices;
      const foundPrice = shopifyPrices.prices.find(
        (price) => price.id === productInfo.id
      );
      data.prices = foundPrice;

      setProduct(data);
    };

    const clubAddToCart = async (product) => {
      setLoading(true);
      let cocunatId = product.sendingClub;

      const lineItemsToAdd = [
        {
          merchandiseId: "gid://shopify/ProductVariant/41996279709861",
          quantity: 1,
          attributes: [{ key: "_cocunatId", value: cocunatId.toString() }],
          sellingPlanId: "gid://shopify/SellingPlan/1363837093",
        },
      ];

      const checkoutUrl = await getCheckoutUrl(lineItemsToAdd, country);
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      } else {
        setLoading(false);
        setError(t("error_unknown"));
      }
    };

    const buttonClickHandler = async (selectedTone = null) => {
      if (behaviour === "addToCart") {
        if (!product.is_productcontainer) {
          clubAddToCart(product);
          return;
        }
        if (productInfo.id === 53228) {
          if (selectedTone) {
            let _product = await getProductById(
              selectedTone.id,
              lang,
              country,
              ""
            );
            console.log(selectedTone);
            _product.data.sendingClub = selectedTone.sendingClub;
            clubAddToCart(_product.data);
            return;
          }
          setIsOpenSelectToneModal(true);
          return;
        } else if (productInfo.id === 53307) {
          window.location = `https://others.cocunat.com/${lang}-${country}/makeup-quiz?type=GOURMET&product=53307`;
        }
      }
    };

    if (redirectTo) {
      return <Redirect push to={redirectTo} />;
    }

    return (
      <>
        {productInfo && product && (
          <div className="LCProductInner">
            {product && (
              <AddClubModal
                isOpenAddClubModal={isOpenAddClubModal}
                setIsOpenAddClubModal={setIsOpenAddClubModal}
                info={productInfo}
                price={product.price}
                isGourmet={false}
                onClickCallback={buttonClickHandler}
              />
            )}

            {productInfo && productInfo.options && (
              <SelectToneModal
                isOpenSelectToneModal={isOpenSelectToneModal}
                setIsOpenSelectToneModal={setIsOpenSelectToneModal}
                options={productInfo.options}
                onClickCallback={buttonClickHandler}
              />
            )}
            <div className="product-item col-md-3 col-sm-4 col-xs-6">
              <div style={{ position: "relative" }}>
                <img
                  src={productInfo.img}
                  alt={productInfo.title}
                  height="355"
                  width="285"
                  loading="lazy"
                />
                {product?.prices && shopifyPrices.prices && (
                  <span class="club-price-tag">
                    <span className="price-product">
                      <PriceWithSymbol
                        amount={product.prices.price}
                        format={product.prices.format}
                        symbol={product.prices.symbol}
                        spanClassName={"strike-price"}
                      />
                      <PriceWithSymbol
                        amount={shopifyPrices.clubPrice.price}
                        format={shopifyPrices.clubPrice.format}
                        symbol={shopifyPrices.clubPrice.symbol}
                        spanClassName={"actual-price"}
                      />
                    </span>
                  </span>
                )}
              </div>

              <div className="product-title">
                <p
                  style={{
                    fontSize: "13px",
                    maxHeight: "200px",
                    overflow: "hidden",
                  }}
                >
                  <strong
                    style={{
                      fontSize: "16px",
                      display: "block",
                      textTransform: "uppercase",
                    }}
                  >
                    {productInfo.title}
                  </strong>
                </p>
                <p style={{ fontSize: 12 }}>
                  <RatingStars rating={5} size={12} color={"black"} />
                  <span style={{ marginLeft: 5 }}>{product.rating}</span>
                </p>
                <p
                  style={{
                    fontSize: "13px",
                    maxHeight: "200px",
                    overflow: "hidden",
                  }}
                >
                  {productInfo.summary}
                </p>
              </div>
              <Button
                loading={loading}
                type={"primary add-club"}
                style={{ backgroundColor: "black", width: "100%" }}
                onClick={() => {
                  if (productInfo.id == 53228) {
                    setIsOpenSelectToneModal(true);
                  } else {
                    setIsOpenAddClubModal(true);
                  }
                }}
              >
                <span style={{ textTransform: "uppercase" }}>
                  {t("new_landing_club.buy_club")}
                </span>
              </Button>
            </div>
            <div
              style={{
                backgroundImage: `url(${productInfo.img})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            ></div>
          </div>
        )}
      </>
    );
  }
);

export default ClubProductGallery;

function UpsellProductControls({ plusOne, minusOne, number }) {
  return (
    <div className={"controls"}>
      <button onClick={minusOne}>-</button>
      <span>{number}</span>
      <button onClick={plusOne}>+</button>
    </div>
  );
}

export default UpsellProductControls;

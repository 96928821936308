import React, { useState, useEffect } from "react";
import { t } from "../../helpers";
import Header from "../../components/Header";
import { getClientSecret } from "../../logic/account";
import "../../css/Account.css";
import StripeSetupIntent from "../../components/StripeSetupIntent";
import { useHistory, useLocation, Redirect, useParams } from "react-router-dom";
import GoBackLink from "../../components/GoBackLink";

function AccountNewPaymentMethod() {
  let { lang, country } = useParams();
  let [clientSecret, setClientSecret] = useState(null);
  let [redirectTo, setRedirectTo] = useState(null);

  const history = useHistory();
  let location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  queryParams.delete("setup_intent");
  queryParams.delete("setup_intent_client_secret");
  const redirect_status = queryParams.get("redirect_status");

  useEffect(() => {
    if (redirect_status === "succeeded") {
      setRedirectTo(true);
    } else {
      history.replace({
        search: queryParams.toString(),
      });
    }
    const fetchData = async () => {
      try {
        const clientSecret = await getClientSecret();
        setClientSecret(clientSecret.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {redirectTo ? (
        <Redirect
          push
          to={`/${lang}-${country}/account/profile/payment_methods`}
        />
      ) : (
        <>
          <Header />
          <div className="container">
            <p
              style={{
                backgroundImage:
                  "url('https://global.cocunat.com/photos/hero-65.jpg')",
                height: "80px",
                textAlign: "center",
                backgroundSize: "cover",
                lineHeight: "80px",
                fontWeight: "bold",
                fontFamily: "Brown-Bold",
                fontSize: "16px",
                margin: "0px",
              }}
            >
              {t("add_payment_method")}
            </p>
            <div id="account">
              <GoBackLink
                to={`/${lang}-${country}/account/profile/payment_methods`}
                title={t("payment_method_back", lang)}
                style={{
                  textDecoration: "none",
                  padding: "10px 0px 20px 0px",
                  color: "#000",
                  display: "block",
                  textAlign: "left",
                }}
              />
              {clientSecret && (
                <StripeSetupIntent clientSecret={clientSecret} />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AccountNewPaymentMethod;

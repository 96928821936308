function PriceWithSymbol({
  amount,
  format = "right",
  symbol = "€",
  spanClassName = "",
}) {
  try {
    amount = amount.toFixed(2);
  } catch (err) {
    console.log(err);
  }
  return (
    <>
      {format === "left" ? (
        amount < 0 ? (
          <span className={spanClassName}>
            -{symbol}
            {(-1 * amount).toFixed(2)}
          </span>
        ) : (
          <span className={spanClassName}>
            {symbol}
            {amount}
          </span>
        )
      ) : (
        <span className={spanClassName}>
          {amount}
          {symbol}
        </span>
      )}
    </>
  );
}

export default PriceWithSymbol;

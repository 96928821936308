import { Link } from "react-router-dom";

function GoBackLink({ to, title, style = null }) {
  style = style || {
    textDecoration: "none",
    padding: "10px 0px",
    color: "#000",
    display: "block",
  };
  return (
    <Link to={to} style={style}>
      &lt; {title}
    </Link>
  );
}

export default GoBackLink;

import React, { useContext, useState } from "react";
import Modal from "../Modal";
import { t } from "../../helpers";
import Button from "../Button";
import Price from "../Price";
import { AccountContext } from "../../context/account";

const AddClubModal = React.memo(
  ({
    isOpenAddClubModal,
    setIsOpenAddClubModal,
    info,
    price,
    isGourmet,
    onClickCallback,
  }) => {
    const { region } = useContext(AccountContext);

    return (
      <>
        {info && (
          <Modal isOpen={isOpenAddClubModal} setIsOpen={setIsOpenAddClubModal}>
            {info && (
              <div className="modalInnerAddClub">
                <h1 className="innerTitle">
                  {t("new_landing_club.add_modal_title_1", {
                    price: region.extra.club_price,
                  })}
                </h1>
                <h2>{t("new_landing_club.add_modal_title_2")}</h2>
                <ul>
                  <li>· {t("new_landing_club.add_modal_1")}</li>
                  <li>· {t("new_landing_club.add_modal_2")}</li>
                  <li>· {t("new_landing_club.add_modal_3")}</li>
                  <li>· {t("new_landing_club.add_modal_4")}</li>
                </ul>

                <Button
                  size="big"
                  type="primary"
                  width="wide"
                  style={{
                    backgroundColor: "black",
                    marginBottom: "10px",
                  }}
                  onClick={(e) => onClickCallback()}
                >
                  <span>{t("new_landing_club.subscribe_club")}</span>
                </Button>
                <small>{t("new_landing_club.add_modal_small")}</small>
              </div>
            )}
          </Modal>
        )}
      </>
    );
  }
);

export default AddClubModal;

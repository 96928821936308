import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "../../css/Club.css";
import { AccountContext } from "../../context/account";
import { t } from "../../helpers";

const Trustpilot = React.memo(() => {
  const { region } = useContext(AccountContext);

  if (!region) {
    return <div></div>;
  }
  return (
    <>
      {region && (
        <section className="club-truspilot">
          <div className="title">
            <img src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_022023_trust_title.png" />
            <img src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_022023_trust_stars.png" />
            <div class="score">
              <h1>{t("new_landing_club.truspilot_excelent")}</h1>
              <p>4,7/5</p>
            </div>
          </div>
          <div class="comments">
            <div class="comments-inner">
              <p className="author">
                {t("new_landing_club.truspilot_author_1")}
              </p>
              <img src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_022023_trust_stars5.png" />
              <p className="comment">
                {t("new_landing_club.truspilot_review_1")}
              </p>
            </div>
            <div class="comments-inner">
              <p className="author">
                {t("new_landing_club.truspilot_author_2")}
              </p>
              <img src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_022023_trust_stars5.png" />
              <p className="comment">
                {t("new_landing_club.truspilot_review_2")}
              </p>
            </div>
            <div class="comments-inner">
              <p className="author">
                {t("new_landing_club.truspilot_author_3")}
              </p>
              <img src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_022023_trust_stars5.png" />
              <p className="comment">
                {t("new_landing_club.truspilot_review_3")}
              </p>
            </div>
          </div>
        </section>
      )}
    </>
  );
});

export default Trustpilot;

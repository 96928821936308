import React, { useState, useEffect } from "react";
import { t } from "../../helpers";
import Header from "../../components/Header";
import {
  getAccountPaymentMethods,
  setDefaultPaymentMethod,
  deletePaymentMethod,
  updatePaymentMethod,
} from "../../logic/account";
import Button from "../../components/Button";
import "../../css/Account.css";
import { Redirect, useParams } from "react-router-dom";

function AccountPaymentMethods() {
  let { lang, country } = useParams();
  let [paymentMethods, setPaymentMethods] = useState(null);
  let [editPaymentMethod, setEditPaymentMethod] = useState(null);
  let [error, setError] = useState(null);
  let [exp_month, setExpMonth] = useState(null);
  let [exp_year, setExpYear] = useState(null);
  let [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accountPaymentMethods = await getAccountPaymentMethods();
        setPaymentMethods(accountPaymentMethods.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  function handleAdd() {
    window.location = `/${lang}-${country}/account/profile/payment_methods/new`;
  }

  const handleEdit =
    (paymentMethodId, paymentMethodMonth, paymentMethodYear) => async () => {
      setExpMonth(paymentMethodMonth);
      setExpYear(paymentMethodYear);
      setEditPaymentMethod(paymentMethodId);
    };

  const handleSetDefault = (paymentMethodId) => async () => {
    setIsLoading(true);
    try {
      const setted = await setDefaultPaymentMethod(paymentMethodId);
      if (setted) {
        window.location = `/${lang}-${country}/account/profile/payment_methods`;
      } else {
        setError(t("payment_method_default_error"));
        setIsLoading(false);
      }
    } catch (e) {
      setError(t("payment_method_default_error"));
      setIsLoading(false);
      console.log(e);
    }
  };

  const handleDelete = (paymentMethodId) => async () => {
    if (window.confirm(t("payment_method_delete_confirm"))) {
      setIsLoading(true);
      try {
        const setted = await deletePaymentMethod(paymentMethodId);
        if (setted) {
          window.location = `/${lang}-${country}/account/profile/payment_methods`;
        } else {
          setError(t("payment_method_delete_error"));
          setIsLoading(false);
        }
      } catch (e) {
        setError(t("payment_method_delete_error"));
        setIsLoading(false);
        console.log(e);
      }
    }
  };

  const handleSave = (paymentMethodId) => async () => {
    setIsLoading(true);
    try {
      await updatePaymentMethod(paymentMethodId, exp_month, exp_year);
      window.location = `/${lang}-${country}/account/profile/payment_methods`;
    } catch (e) {
      setError(t("payment_method_update_error"));
      setIsLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Header />
      <div className="container">
        <p
          style={{
            backgroundImage:
              "url('https://global.cocunat.com/photos/hero-65.jpg')",
            height: "80px",
            textAlign: "center",
            backgroundSize: "cover",
            lineHeight: "80px",
            fontWeight: "bold",
            fontFamily: "Brown-Bold",
            fontSize: "16px",
            margin: "0px",
          }}
        >
          {t("my_payment_methods")}
        </p>
        <div id="account">
          {paymentMethods === null ? (
            <div>
              <img
                src="https://global.cocunat.com/icons/loading.gif"
                height="25"
                width="25"
                alt="Loading"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </div>
          ) : (
            <>
              {error && <div className="alert alert-danger">⚠ {error}</div>}
              {paymentMethods.length > 0 ? (
                <>
                  {paymentMethods.map((paymentMethod, index) => {
                    return (
                      <div key={index} className="account-payment-method">
                        <div>
                          <p className="payment-method-header">
                            {paymentMethod.brand} **** {paymentMethod.last4}
                            {paymentMethod.default && (
                              <span className="payment-method-default">
                                {t("default")}
                              </span>
                            )}
                          </p>
                          {editPaymentMethod !== paymentMethod.id && (
                            <>
                              <div className="payment-method-button">
                                <Button
                                  size="small"
                                  onClick={handleEdit(
                                    paymentMethod.id,
                                    paymentMethod.exp_month,
                                    paymentMethod.exp_year
                                  )}
                                >
                                  <span>{t("edit_payment_method")}</span>
                                </Button>
                              </div>
                              <p className="payment-method-expiration">
                                {t("expiration")} {paymentMethod.exp_month}/
                                {paymentMethod.exp_year}
                              </p>
                            </>
                          )}
                        </div>
                        {editPaymentMethod === paymentMethod.id && (
                          <div className="payment-method-edit">
                            <p>
                              <label>{t("expiration")}</label>
                              <input
                                name="exp_month_{{paymentMethod.id}}"
                                id="exp_month_{{paymentMethod.id}}"
                                value={exp_month}
                                type="text"
                                className="payment-method-month"
                                onChange={(e) => setExpMonth(e.target.value)}
                              />
                              /
                              <input
                                name="exp_year_{{paymentMethod.id}}"
                                id="exp_year_{{paymentMethod.id}}"
                                value={exp_year}
                                type="text"
                                className="payment-method-year"
                                onChange={(e) => setExpYear(e.target.value)}
                              />
                            </p>
                            <p>
                              {!paymentMethod.default && (
                                <>
                                  <input
                                    type="checkbox"
                                    name="default_{{paymentMethod.id}}"
                                    onChange={handleSetDefault(
                                      paymentMethod.id
                                    )}
                                  />
                                  <label> {t("set_default")}</label>
                                </>
                              )}
                            </p>
                            <div className="payment-method-buttons">
                              {isLoading ? (
                                <>
                                  <Button
                                    size="small"
                                    onClick={() => console.log("Loading")}
                                  >
                                    <img
                                      src="https://global.cocunat.com/icons/loading.gif"
                                      height="25"
                                      width="25"
                                      alt="Loading"
                                      style={{
                                        display: "block",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                      }}
                                    />
                                  </Button>
                                  {paymentMethods.length > 1 && (
                                    <Button
                                      size="small"
                                      onClick={() => console.log("Loading")}
                                    >
                                      <img
                                        src="https://global.cocunat.com/icons/loading.gif"
                                        height="25"
                                        width="25"
                                        alt="Loading"
                                        style={{
                                          display: "block",
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                        }}
                                      />
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Button
                                    size="small"
                                    onClick={handleSave(paymentMethod.id)}
                                  >
                                    <span>{t("save")}</span>
                                  </Button>
                                  {paymentMethods.length > 1 && (
                                    <Button
                                      size="small"
                                      onClick={handleDelete(paymentMethod.id)}
                                    >
                                      <span>{t("delete_payment_method")}</span>
                                    </Button>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <p>{t("no_payment_methods_yet")}</p>
              )}
              {paymentMethods.length < 10 && (
                <div className="account-payment-method-button">
                  {isLoading ? (
                    <Button size="small" onClick={() => console.log("Loading")}>
                      <img
                        src="https://global.cocunat.com/icons/loading.gif"
                        height="25"
                        width="25"
                        alt="Loading"
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                    </Button>
                  ) : (
                    <Button size="small" onClick={handleAdd}>
                      <span>{t("add")}</span>
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AccountPaymentMethods;

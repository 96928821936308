import axios from "axios";
import { getHeadersWithoutAuth } from "../helpers";
import { t } from "../../helpers";

const WORKER_STRIPE =
  process.env.REACT_APP_STRIPE_API || "https://stripe.cocunat.com/v1/";
export const STRIPE_KEY =
  process.env.REACT_APP_STRIPE_KEY || "pk_live_3sLfrd8FfRe7HMGL1CBKRh7c";

export async function updatePaymentIntent(
  payment_intent_id,
  cartWithTotals,
  address
) {
  return axios.patch(
    WORKER_STRIPE + "intent/" + payment_intent_id,
    paymentIntentPayload(cartWithTotals, address),
    getHeadersWithoutAuth()
  );
}

export function payUpsellCheckout(cartData) {
  return axios.post(
    WORKER_STRIPE + "customers/charge",
    cartData,
    getHeadersWithoutAuth()
  );
}

/**
 * For application/x-www-form-urlencoded, spaces are to be replaced by "+",
 * so one may wish to follow a encodeURIComponent() replacement with an additional
 * replacement of "%20" with "+".
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
 *
 * @param {*} str
 */
function encodeUrlFormEncoded(str) {
  return encodeURIComponent(str).replace(/%20/g, "+");
}

function priceFormatGateway(amount) {
  return parseInt(amount * 100);
}

function paymentIntentPayload(cartWithTotals, address) {
  if (cartWithTotals.totals.total === undefined) {
    throw new Error("Total is required");
  }
  let phone = address.phone || "";
  let userData = {
    id: null,
    email: address.email,
    first_name: address.first_name,
    last_name: address.last_name,
  };
  const payload = {
    amount: priceFormatGateway(cartWithTotals.totals.total),
    currency: cartWithTotals.currency.toLowerCase(),
    description: "COCUNAT.COM",
    phone: phone,
    promo_code: cartWithTotals.promo_code,
    language: cartWithTotals.language,
    orderlines: cartWithTotals.orderlines,
    totals: cartWithTotals.totals,
    address: { ...address },
    region: cartWithTotals.region,
    cart_id: cartWithTotals.cart_id,
    meta: cartWithTotals.meta,
    userData: userData,
  };
  delete payload.totals.rewards;
  delete payload.totals.currency;

  payload.phone = encodeUrlFormEncoded(payload.phone);
  Object.keys(payload.address).forEach((key) => {
    payload.address[key] = encodeUrlFormEncoded(payload.address[key]);
  });
  return payload;
}

export function paymentRequestPayload(cart) {
  return {
    country: cart.address.country.toUpperCase(),
    currency: cart.currency.toLowerCase(),
    total: {
      label: t("total"),
      amount: priceFormatGateway(cart.totals.total),
    },
    disabledWallets: ["browserCard"],
  };
}

export async function getPaymentIntent(cartWithTotals, address) {
  return axios.post(
    WORKER_STRIPE + "intent/",
    paymentIntentPayload(cartWithTotals, address),
    getHeadersWithoutAuth()
  );
}

/**
 * The IETF language tag of the locale to display placeholders and error strings in Stripe.
 * @param {*} current_lang
 */
export function getStripeLocale(current_lang) {
  try {
    current_lang = current_lang.toLowerCase();
  } catch (e) {
    console.log(e);
  }

  let supportedLocales = [
    "ar",
    "da",
    "de",
    "en",
    "es",
    "fi",
    "fr",
    "he",
    "it",
    "ja",
    "lt",
    "lv",
    "ms",
    "nb",
    "nl",
    "pl",
    "pt",
    "ru",
    "sv",
    "zh",
  ];
  if (supportedLocales.includes(current_lang)) {
    return current_lang;
  } else {
    return "en";
  }
}

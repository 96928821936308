import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "../../css/Club.css";
import ShowMore from "../ShowMore";
import { AccountContext } from "../../context/account";
import { t } from "../../helpers";
import { formatPrice } from "../../logic/helpers";

const Faqs = React.memo(() => {
  const { region } = useContext(AccountContext);
  let { lang } = useParams();
  const [translations, setTranslations] = useState(null);
  let clubPrice = "";
  let shippingPrice = "";

  try {
    shippingPrice = formatPrice(
      region.carriers[0].free_shipping,
      region.symbol,
      region.format
    );
  } catch (e) {
    console.log(e);
  }

  try {
    clubPrice = region.extra.club_price;
  } catch (e) {
    console.log(e);
  }

  let _translations = {
    es: {
      faqs_title: `¿Tienes dudas?`,
      faqs_title_1: `¿CUÁNTOS PRODUCTOS CLUB PUEDO COMPRAR POR  ${clubPrice} AL MES?`,
      faqs_text_1: `Con tu suscripción CLUB, solo se puede adquirir un producto CLUB por ${clubPrice} + gastos de envío, pero siempre que lo necesites puedes añadir un producto extra con un mínimo de 10% de descuento garantizado y tendrás los gastos de envío gratuitos.`,
      faqs_title_2: `¿QUÉ PRODUCTO RECIBO CON  CLUB?`,
      faqs_text_2: `Puedes repetir producto o elegir uno nuevo de la selección CLUB. Los productos CLUB tienen los mismos mililitros pero con un packaging reducido, no llevan accesorios, como el turbante en nuestra mascarilla capilar Boombastic, la brocha y la esponja en el caso de Wondermask y la paleta de aplicación en el caso de The Absolute. Recuerda seleccionar el producto que prefieres recibir antes de tu siguiente entrega desde tu Perfil Cliente CLUB antes de la fecha de renovación. `,
      faqs_title_3: `¿DEBO PAGAR ALGÚN CONCEPTO MÁS QUE LOS  ${clubPrice}/MES?`,
      faqs_text_3: `Sí, al darte de alta en CLUB los gastos de envío se añaden en el proceso de compra, ten en cuenta que el precio de club varía según el país de destino.<br>
      A partir de la segunda entrega, el cobro se realiza el mismo día de la renovación de tu pedido de manera automática y una vez realizado, se prepara el pedido para el envío.  <br>
      Añade desde tu cuenta cliente, un producto extra* a tu siguiente entrega y tendrás un 10% de descuento asegurado  y los gastos de envío gratuitos.<br>
      *Excepto  Beauty Base, Magic  Brush, Cheek  Boost , Blush , All in one, Browliner, Trials<br>
      *Al añadir producto a tu pedido Club no es posible utilizar códigos descuento, solo se aplicará el descuento disponible en web en ese momento. <br>
      " `,
      faqs_title_4: `¿HASTA CUÁNDO DURA LA MEMBRESÍA AL CLUB?`,
      faqs_text_4: `Tienes una permanencia mínima de 6 meses con un envío al mes con renovación automática. Si después de la permanencia mínima, quieres cancelar tu suscripción, accede a tu cuenta cliente y solicita la cancelación antes de tu sexta entrega. Ten en cuenta que la  suscripción se renueva cada 28 días`,
      faqs_title_5: `¿QUE SUCEDE SI NO SELECCIONO MI PRODUCTO CLUB PARA LA PROXIMA ENTREGA?`,
      faqs_text_5: `Es importante seleccionar el producto que prefieres recibir antes de tu siguiente entrega, puedes realizarlo desde tu Perfil Cliente CLUB en cualquier momento del mes, pero antes de la fecha de renovación,  en el caso de que no selecciones un  producto te asignaremos un producto CLUB sorpresa que solo podrás cambiar mediante devolución. `,
      faqs_title_6: `¿Puedo disfrutar de una promoción para nuevas altas si ya soy miembro de Club?`,
      faqs_text_6: `Si ya eres miembro de Club no podrás disfrutar de promociones específicas por darte de alta, pero no te preocupes por qué tienes acceso a promociones específicas para premiar tu fidelidad.`,
    },
    en: {
      faqs_title: `You have any questions?		`,
      faqs_title_1: `HOW MANY CLUB PRODUCTS CAN I BUY FOR ${clubPrice} PER MONTH?`,
      faqs_text_1: `With your CLUB subscription, you can only purchase one CLUB product for ${clubPrice} + shipping costs.  You can always add an extra product with a minimum of 10% discount guaranteed and free shipping costs.`,
      faqs_title_2: `WHAT PRODUCT DO I RECEIVE WITH CLUB?`,
      faqs_text_2: `You can repeat products or choose a new one from the CLUB selection. CLUB products have the same milliliters but with reduced packaging, no accessories, such as the turban in our Boombastic hair mask, the brush and sponge in the case of Wondermask and the application palette in the case of The Absolute.<br>

      Remember you will need select the product you prefer to receive before your next delivery from your CLUB Customer Profile before the renewal date.`,
      faqs_title_3: `DO I HAVE TO PAY MORE THAN THE ${clubPrice}/MONTH?`,
      faqs_text_3: `Yes, when you register to CLUB the shipping costs are added in the purchase transaction, these shipping costs depend on the country of destination. <br>

      From the second delivery, the payment is made the same day of the renewal of your order automatically and once done, the order is prepared for shipment.<br>
      
      Add from your customer account, an extra product* to your next delivery and you will have a 10% discount and free shipping costs.<br>
      
      *Except Beauty Base, Magic Brush, Cheek Boost, Blush, All in one, Browliner, Trials.<br>
      *When adding product to your Club order it is not possible to use discount codes, only the discount available on web at that moment will be applied. `,
      faqs_title_4: `HOW LONG DOES THE CLUB MEMBERSHIP LAST?	`,
      faqs_text_4: `You have a minimum permanence of 6 months with one shipment per month with automatic renewal. If after the minimum term, you want to cancel your subscription, log in to your customer account and request cancellation before your sixth delivery. Please note that the subscription renews every 28 days.`,
      faqs_title_5: `WHAT HAPPENS IF I DON'T SELECT MY CLUB PRODUCT FOR THE NEXT DELIVERY?		`,
      faqs_text_5: `If one month you don't select the product you prefer to receive, we will assign you a surprise CLUB product.`,
      faqs_title_6: `Can I enjoy a promotion for new members if I am already a Club member?`,
      faqs_text_6: ` If you are already a Club member you will not be able to enjoy specific promotions for new members, but don't worry because you have access to specific promotions to reward your loyalty.`,
    },
    fr: {
      faqs_title: `Avez-vous des questions ?`,
      faqs_title_1: `COMBIEN DE PRODUITS DU CLUB PUIS-JE ACHETER POUR ${clubPrice} PAR MOIS ?`,
      faqs_text_1: `Avec votre abonnement CLUB, vous ne pouvez acheter qu'un seul produit CLUB pour ${clubPrice} + frais de port, mais à chaque fois que vous en avez besoin, vous pouvez ajouter un produit supplémentaire avec un minimum de 10% de réduction garantie et vous aurez les frais de port gratuits.`,
      faqs_title_2: `QUEL EST LE PRODUIT QUE J'OBTIENS AVEC LE CLUB ?`,
      faqs_text_2: `
      Les produits CLUB ont les mêmes millilitres mais avec un emballage réduit et sans accessoires, comme le turban de notre masque capillaire Boombastic, la brosse et l'éponge de Wondermask et la palette d'application de The Absolute.
      
      N'oubliez pas de sélectionner le produit que vous préférez recevoir avant votre prochaine livraison dans votre profil client CLUB avant la date de renouvellement. <br>`,
      faqs_title_3: `DOIS-JE PAYER PLUS QUE LES  ${clubPrice}/MOIS?`,
      faqs_text_3: `Oui, lorsque vous vous inscrivez au CLUB, les frais d'expédition sont ajoutés dans le processus d'achat, ces frais d'expédition dépendent du pays de destination. <br>

      A partir de la deuxième livraison, le paiement est effectué le jour même du renouvellement de votre commande automatiquement et une fois que c'est fait, la commande est préparée pour l'expédition.<br>
      
      Ajoutez depuis votre compte client, un produit supplémentaire* à votre prochaine livraison et vous bénéficierez d'une remise de 10% et des frais de port gratuits.<br>
      
      *Sauf Beauty Base, Magic Brush, Cheek Boost, Blush, All in one, Browliner, Trials.<br>
      *Lors de l'ajout d'un produit à votre commande Club, il n'est pas possible d'utiliser des codes de réduction, seule la réduction disponible sur le web à ce moment-là sera appliquée. <br>
       `,
      faqs_title_4: `COMBIEN DE TEMPS DURE L'ADHÉSION AU CLUB ?`,
      faqs_text_4: `Vous disposez d'un séjour minimum de 6 mois avec une livraison par mois avec renouvellement automatique. Si, après la période minimale, vous souhaitez résilier votre abonnement, veuillez vous connecter à votre compte client et demander la résiliation avant votre sixième livraison. Veuillez noter que l'abonnement est renouvelé tous les 28 jours.`,
      faqs_title_5: `QUE SE PASSE-T-IL SI JE NE SÉLECTIONNE PAS MON PRODUIT CLUB POUR LA PROCHAINE LIVRAISON ?`,
      faqs_text_5: `Si un mois, vous ne sélectionnez pas le produit que vous préférez recevoir, nous vous attribuerons un produit CLUB surprise.`,
      faqs_title_6: `Puis-je bénéficier d'une promotion nouveau membre si je suis déjà membre du Club ?`,
      faqs_text_6: `Si vous êtes déjà membre du Club, vous ne pourrez pas bénéficier de promotions spécifiques pour l’adhésion, mais ne vous inquiétez pas car vous avez accès à des promotions spécifiques pour récompenser votre fidélité.`,
    },
    it: {
      faqs_title: `Iscriviti al CLUB`,
      faqs_title_1: `QUANTI PRODOTTI DEL CLUB POSSO ACQUISTARE PER ${clubPrice} AL MESE? `,
      faqs_text_1: `Con il tuo abbonamento CLUB. potrai comprare solo un prodotto CLUB per ${clubPrice} +spese di spedizione, peró potrai sempre aggiungere un prodotto extra con un minimo di 10% di SCONTO garantito e avrai le spese di spedizione gratis`,
      faqs_title_2: `CHE PRODOTTO RICEVO CON CLUB?`,
      faqs_text_2: `Puoi ripetere il prodotto o scegliere uno differente della selezione CLUB. I prodotti CLUB hanno gli stessi millilitri peró un packaging ridotto, non comprendono gli accessori, come il turbante nella nostra maschera BOOMBASTIC, la spugna ed il pennello nel caso della WONDERMASK e la spatolina nel caso di ABSOLUTE. Ricordati di selezionare il prodotto che vorrai ricevere prima del tuo prossimo invio nel tuo Profilo Cliente CLUB prima della data di rinnovo.`,
      faqs_title_3: `DEVO PAGARE PIÙ DI ${clubPrice} AL MESE?`,
      faqs_text_3: `"Sì, quando ci si iscrive al CLUB le spese di spedizione vengono aggiunte nel processo di acquisto, tali spese dipendono dal paese di destinazione. <br>

      A partire dalla seconda consegna, il pagamento viene effettuato il giorno stesso del rinnovo dell'ordine automaticamente e, una volta effettuato, l'ordine viene preparato per la spedizione.<br>
      
      Aggiungendo dal tuo conto cliente un prodotto extra* alla tua prossima consegna, avrai uno sconto del 10% e spese di spedizione gratuite.<br>
      
      *Esclusi Beauty Base, Magic Brush, Cheek Boost, Blush, All in one, Browliner, Trials.<br>
      *Quando si aggiungono prodotti all'ordine del Club non è possibile utilizzare codici di sconto, verrà applicato solo lo sconto disponibile sul web in quel momento. 
      "`,
      faqs_title_4: `QUANTO DURA L'ISCRIZIONE AL CLUB?`,
      faqs_text_4: `Hai una permanenza minima di 6 mesi con un envio al mese con rinnovo automatico, Se dopo la permanenza minima vuoi cancellare il tuo abbonamento, accedi al tuo profilo CLIENTE e chiedi la cancellazione prima del tuo sesto invio.Ricorda che  l'abbonamento si rinnova ogni 28 giorni.`,
      faqs_title_5: `CHE SUCCEDE SE NON SELEZIONO IL PRODOTTO CLUB PER IL PROSSIMO INVIO?`,
      faqs_text_5: `È Importante selezionare il prodotto che preferisci ricevere prima del tuo prossimo invio, puoi effettuarlo dal tuo Profilo Cliente CLUB in qualsiasi momento del mese, peró prima della data di rinnovo, nel caso in cui non selezioni il prodotto, ti assegneremo un prodotto CLUB sorpresa che potrai cambiare solo attraverso un reso.`,
      faqs_title_6: `Posso usufruire di una promozione per i nuovi soci se sono già socio del Club?`,
      faqs_text_6: `Se siete già soci del Club non potrete usufruire di promozioni specifiche per l'adesione, ma non preoccupatevi perché avrete accesso a promozioni specifiche per premiare la vostra fedeltà.`,
    },
    de: {
      faqs_title: `Hast du Zweifel?`,
      faqs_title_1: `WIE VIELE CLUBPRODUKTE KANN ICH FÜR ${clubPrice} PRO MONAT KAUFEN?`,
      faqs_text_1: `Mit deinem CLUB-Abonnement kannst du nur ein CLUB-Produkt für ${clubPrice} + Versandkosten kaufen, aber immer wenn du möchtest, kannst du ein zusätzliches Produkt mit einem garantierten Mindestrabatt von 10% hinzufügen und du erhältst kostenlose Versandkosten.`,
      faqs_title_2: `WELCHES PRODUKT ERHALTE ICH MIT DEM CLUB?`,
      faqs_text_2: `Du kannst ein Produkt wiederholen oder ein neues Produkt aus dem CLUB-Sortiment wählen. Die CLUB-Produkte haben die gleichen Milliliter, aber eine reduzierte Verpackung und kein Zubehör, wie z. B. den Turban bei unserer Boombastic-Haarmaske, den Pinsel und den Schwamm bei der Wondermask und die Anwendungspalette bei The Absolute.                                                                   <br>


      Bitte vergiss nicht, vor dem Verlängerungsdatum in Ihrem CLUB-Kundenprofil das Produkt auszuwählen, das du vor deiner nächsten Lieferung erhalten möchtest.`,
      faqs_title_3: `SOLL ICH MEHR ALS DIE  ${clubPrice}/MONAT BEZAHLEN?`,
      faqs_text_3: `"Ja, wenn du dich im CLUB registrierst, werden die Versandkosten im Kaufprozess hinzugefügt, diese Versandkosten hängen vom Bestimmungsland ab. <br>

      Ab der zweiten Lieferung wird die Zahlung am selben Tag der Erneuerung deiner Bestellung automatisch durchgeführt und sobald dies geschehen ist, wird die Bestellung für den Versand vorbereitet.<br>
      
      Wenn du von deinem Kundenkonto aus ein extra Produkt* zu deiner nächsten Lieferung hinzufügst, erhältst du einen Rabatt von 10% und kostenlose Versandkosten.<br>
      
      *Ausgenommen Beauty Base, Magic Brush, Cheek Boost, Blush, All in one, Browliner, Trials<br>
      *Wenn Sie ein Produkt zu Ihrer Club-Bestellung hinzufügen, ist es nicht möglich, Rabattcodes zu verwenden, es wird nur der zu diesem Zeitpunkt im Internet verfügbare Rabatt angewendet. "`,
      faqs_title_4: `WIE LANGE DAUERT DIE CLUBMITGLIEDSCHAFT?`,
      faqs_text_4: `Du hast eine Mindestabonnementdauer von 6 Monaten mit einer Lieferung pro Monat mit automatischer Erneuerung. Wenn du nach der Mindestlaufzeit dein Abonnement kündigen möchtest, kannst du dich in dein Kundenkonto einloggen und die Kündigung vor deiner sechsten Lieferung beantragen. Bitte beachte, dass das Abonnement alle 28 Tage erneuert wird.`,
      faqs_title_5: `WAS PASSIERT, WENN ICH MEIN CLUBPRODUKT NICHT FÜR DIE NÄCHSTE LIEFERUNG AUSWÄHLE?`,
      faqs_text_5: `Es ist wichtig, dass du vor der nächsten Lieferung das Produkt auswählst, das du erhalten möchtest. Du kannst das jederzeit im Monat in deinem CLUB-Kundenprofil tun, aber vor dem Verlängerungsdatum, falls du kein Produkt auswählst, werden wir dir ein CLUB-Überraschungsprodukt zuweisen, das du nur durch Rückgabe ändern kannst.`,
      faqs_title_6: `Kann ich eine Werbeaktion für neue Mitglieder in Anspruch nehmen, wenn ich bereits Mitglied im Club bin?`,
      faqs_text_6: `Wenn Sie bereits Clubmitglied sind, können Sie nicht in den Genuss spezieller Werbeaktionen für den Beitritt kommen. Aber keine Sorge, Sie haben Zugang zu speziellen Werbeaktionen, um Ihre Treue zu belohnen.`,
    },
  };

  if (!translations) {
    setTranslations(_translations[lang]);
  }

  try {
    if (region && !clubPrice) {
    }

    if (region && !shippingPrice) {
    }
  } catch (e) {
    console.log(e);
  }

  if (!region) {
    return <div></div>;
  }

  return (
    <>
      {translations && region && clubPrice && shippingPrice && (
        <section className="faqs container-wide">
          <h1>{translations.faqs_title}</h1>
          <div className="faqsInner">
            <ShowMore
              title={translations.faqs_title_1}
              text={translations.faqs_text_1}
              type={"dropdown"}
            />
          </div>
          <div className="faqsInner">
            <ShowMore
              title={translations.faqs_title_2}
              text={translations.faqs_text_2}
              type={"dropdown"}
            />
          </div>
          <div className="faqsInner">
            <ShowMore
              title={translations.faqs_title_5}
              text={translations.faqs_text_5}
              type={"dropdown"}
            />
          </div>
          <div className="faqsInner">
            <ShowMore
              title={translations.faqs_title_4}
              text={translations.faqs_text_4}
              type={"dropdown"}
            />
          </div>
          <div className="faqsInner">
            <ShowMore
              title={translations.faqs_title_3}
              text={translations.faqs_text_3}
              type={"dropdown"}
            />
          </div>
          <div className="faqsInner">
            <ShowMore
              title={translations.faqs_title_6}
              text={translations.faqs_text_6}
              type={"dropdown"}
            />
          </div>
        </section>
      )}
    </>
  );
});

export default Faqs;

import React, { useState, useEffect } from "react";
import { t } from "../../helpers";
import Header from "../../components/Header";
import { Link, useParams } from "react-router-dom";
import { getAccountSubscriptions } from "../../logic/account";

function AccountSubscriptions() {
  let { lang, country } = useParams();
  let [subscriptions, setSubscriptions] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const subscriptions = await getAccountSubscriptions();
        setSubscriptions(subscriptions.data);

        if (subscriptions.data.length    <= 0) {
          window.location.href = `/${lang}-${country}/club`;
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <div className="container">
        <p
          style={{
            backgroundImage:
              "url('https://global.cocunat.com/photos/hero-65.jpg')",
            height: "80px",
            textAlign: "center",
            backgroundSize: "cover",
            lineHeight: "80px",
            fontWeight: "bold",
            fontFamily: "Brown-Bold",
            fontSize: "16px",
            margin: "0px",
          }}
        >
          {t("my_subscriptions")}
        </p>
        <div id="account">
          {subscriptions.length > 0 && (
            <>
              {subscriptions.length > 0 ? (
                <>
                  {subscriptions.map((subscription, index) => {
                    return (
                      <div key={index} className="account-subscriptions">
                        <div className="account-subscription-header">
                          <p
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            {!subscription.subscription_type.includes(
                              "GOURMET"
                            ) ? (
                              <strong>
                                {subscription.next_product &&
                                subscription.next_product.name
                                  ? subscription.next_product.name
                                  : subscription.subscription_type}
                              </strong>
                            ) : (
                              <strong>CLUB</strong>
                            )}
                          </p>
                          {subscription.status === "active" ? (
                            <p>
                              {t("subscription_next")}:{" "}
                              {new Date(
                                subscription.next_charge
                              ).toLocaleDateString()}
                            </p>
                          ) : (
                            <p>{subscription.status.toUpperCase()}</p>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Link
                            to={`/${lang}-${country}/account/profile/subscriptions/${subscription.subscription_type}/${subscription.uuid}`}
                          >
                            {t("edit_address")}
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="account-subscriptions">
                    <div className="account-subscription-header">
                      <p>
                        <strong>{subscriptions.subscription_type}</strong>
                      </p>
                      {subscriptions.status === "active" ? (
                        <p>
                          {" "}
                          {t("subscription_next")}:{" "}
                          {new Date(
                            subscriptions.next_charge
                          ).toLocaleDateString()}
                        </p>
                      ) : (
                        <p>{subscriptions.status}</p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Link
                        to={`/${lang}-${country}/account/profile/subscriptions/${subscriptions.subscription_type}/${subscriptions.uuid}`}
                      >
                        {t("edit_address")}
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {subscriptions.length < 1 && <p>{t("no_subscription_yet")}</p>}
        </div>
      </div>
    </>
  );
}

export default AccountSubscriptions;

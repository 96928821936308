function SimpleToast({ title, message, onClose, success }) {
  return (
    <div
      style={{
        backgroundColor: success ? "#EBF6F4" : "#F5F5F5",
        borderBottom: success ? "2px solid #62C15A" : "2px solid #E53935",
        padding: "0px 15px",
        margin: "15px",
        position: "fixed",
        zIndex: "999",
        width: "calc(100% - 30px)",
      }}
    >
      <span
        style={{
          color: "#767676",
          fontFamily: "Brown-Regular",
          position: "absolute",
          top: 0,
          right: 0,
          padding: "5px 10px",
          cursor: "pointer",
        }}
        onClick={onClose}
      >
        X
      </span>
      <p>
        <strong
          style={{
            color: success ? "#62C15A" : "#E53935",
            fontSize: "14px",
          }}
        >
          {title}
        </strong>
      </p>
      <p style={{ fontSize: "12px", marginLeft: "15px" }}>{message}</p>
    </div>
  );
}

export default SimpleToast;

import React, { useState } from "react";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as stripePayments from "../logic/payments/stripe";
import { useParams } from "react-router-dom";
import Button from "./Button";
import { t } from "../helpers";

const stripePromise = loadStripe(stripePayments.STRIPE_KEY);

function StripePaymentElement() {
  let { lang, country } = useParams();
  const [showButton, setShowButton] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = (stripe, elements) => async () => {
    setIsLoading(true);
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `https://cocunat.com/${lang}-${country}/profile/payment_methods/new`,
      },
    });
    if (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <form>
      <PaymentElement />
      {isLoading ? (
        <Button
          style={{ marginTop: "100px" }}
          size="big"
          width="wide"
          onClick={() => console.log("Loading stripe payment")}
        >
          <img
            src="https://global.cocunat.com/icons/loading.gif"
            height="25"
            width="25"
            alt="Loading"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </Button>
      ) : (
        <div>
          <Button
            style={{ marginTop: "100px" }}
            size="big"
            width="wide"
            onClick={handleSubmit(stripe, elements)}
          >
            <span>{t("add")}</span>
          </Button>
          <p>{errorMessage && <div>{errorMessage}</div>}</p>
        </div>
      )}
    </form>
  );
}

function StripeSetupIntent(props) {
  let { lang } = useParams();
  const options = {
    clientSecret: props.clientSecret,
    locale: stripePayments.getStripeLocale(lang),
  };

  return (
    <div>
      <Elements stripe={stripePromise} options={options}>
        <StripePaymentElement />
      </Elements>
    </div>
  );
}

export default StripeSetupIntent;

import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

const Header = React.memo(() => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  let { lang, country } = useParams();

  useEffect(() => {
    if (showMenu) {
      setShowMenu(false);
    }
  }, [location]);

  return (
    <>
      <header>
        <div className="container">
          <a href={`https://cocunat.com/`}>
            <img
              decoding="async"
              alt="Cocunat"
              height="22"
              width="143"
              src="https://resizing.cocunat.com/r/?quality=100&amp;fit=cover&amp;image=https://global.cocunat.com/photos/logo.png&amp;width=143&amp;height=22"
            />
          </a>
          <Link
            to={`/${lang}-${country}/account`}
            style={{ position: "absolute", top: 9, right: 50 }}
          >
            <img
              decoding="async"
              alt="Cocunat"
              height="31"
              width="29"
              src="https://global.cocunat.com/account-black.png"
            />
          </Link>
        </div>
      </header>
      <img
        width="99999"
        height="99999"
        style={{
          pointerEvents: "none",
          position: "absolute",
          top: 0,
          left: 0,
          width: "99vw",
          height: "99vh",
          maxWidth: "99vw",
          maxHeight: "99vh",
        }}
        alt="-"
        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5OTk5OXB4IiBoZWlnaHQ9Ijk5OTk5cHgiIHZpZXdCb3g9IjAgMCA5OTk5OSA5OTk5OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBzdHJva2U9Im5vbmUiIGZpbGw9Im5vbmUiIGZpbGwtb3BhY2l0eT0iMCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9Ijk5OTk5IiBoZWlnaHQ9Ijk5OTk5Ij48L3JlY3Q+IDwvZz4gPC9zdmc+"
      ></img>
    </>
  );
});

export default Header;

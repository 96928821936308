import React, { useEffect, useState } from "react";
import { getURLParams, t } from "../helpers";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { loginUserSSO } from "../logic/account";
import LoginForm from "../components/LoginForm";

function Login() {
  const location = useLocation();
  let { lang, country } = useParams();
  let [email, setEmail] = useState("");
  let [redirectTo, setRedirectTo] = useState("");
  let [password, setPassword] = useState("");
  let [openForgetPassword, setOpenForgetPassword] = useState(false);
  let [newClub, setNewClub] = useState(null);
  useEffect(() => {
    let urlParams = getURLParams(location.search);
    if (urlParams && urlParams.sso_email && urlParams.sso_code) {
      autoSignIn(urlParams.sso_email, urlParams.sso_code);
    }

    if (urlParams && urlParams.c) {
      console.log(urlParams);
      if (urlParams.c === "0") {
        setNewClub(true);
      } else {
        setNewClub(false);
      }
    }
  }, []);
  if (redirectTo) {
    return <Redirect push to={redirectTo} />;
  }

  const autoSignIn = async (sso_email, sso_code) => {
    try {
      let response = await loginUserSSO({
        email: decodeURIComponent(sso_email),
        code: sso_code,
      });
      if (response.status === 200) {
        localStorage.setItem("jwt", response.data.token);
        window.location.href = `/${lang}-${country}/account/`;
      }
    } catch (err) {}
  };

  return (
    <div
      id="page-login"
      style={{
        backgroundColor: "#fed0d2",
        height: "calc(100vh + 32px)",
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="box"
        style={{
          background: "#fff",
          maxWidth: 500,
          padding: 40,
          width: "100%",
          position: "relative",
        }}
      >
        <a href={`/${lang}-${country}/`} className="close-button">
          ✕
        </a>
        <p
          style={{
            fontSize: 28,
            marginBottom: 35,
            marginTop: 0,
            textAlign: "center",
          }}
        >
          {newClub ? (
            <> {t("new_club.login_club")}</>
          ) : (
            <> {t("go_to_cocunat")}</>
          )}
        </p>
        <LoginForm
          redirectSuccess={`/${lang}-${country}/account/`}
          buttonTitle={newClub ? t("sub_continue") : t("login")}
        />
      </div>
    </div>
  );
}

export default Login;

import React, { useState } from "react";

import { t } from "../helpers";

const ShowMore = React.memo((props) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      {!props.type ? (
        <div className="showMoreButton">
          {showMore ? (
            <div
              dangerouslySetInnerHTML={{
                __html: props.text,
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: `${props.text
                  .split("<ul>")[0]
                  .substring(0, props.maxLength)}...`,
              }}
            />
          )}
          <p
            style={{ textDecoration: "underline" }}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? <>{t("readLess")}</> : <>{t("readMore")}</>}
          </p>
        </div>
      ) : (
        <div
          className="showMoreButton"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {showMore ? (
            <>
              <div>
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: props.title,
                  }}
                  onClick={() => setShowMore(!showMore)}
                />
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: props.text,
                  }}
                  onClick={() => setShowMore(!showMore)}
                />
              </div>
            </>
          ) : (
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: props.title,
              }}
              onClick={() => setShowMore(!showMore)}
            />
          )}
          <span style={{}} onClick={() => setShowMore(!showMore)}>
            {showMore ? <>{"-"}</> : <>{"+"}</>}
          </span>
        </div>
      )}
    </>
  );
});

export default ShowMore;

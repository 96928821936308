import axios from "axios";
import constants from "../constants";

const shopifyClient = axios.create({
  baseURL: "https://cocunat.myshopify.com/api/2022-10/graphql.json",
  headers: {
    "X-Shopify-Storefront-Access-Token": "11f5f08f8dcbb599a1d5ebf792f42dce",
  },
});

export async function getCheckoutUrl(lines, country, email) {
  /** Creates the cart and returns the checkout URL */

  const mutation = `mutation cartCreate($input: CartInput!) {
      cartCreate(input: $input) {
        cart {
          id
          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }`;
  try {
    const response = await shopifyClient.post("", {
      query: mutation,
      variables: {
        input: {
          lines,
          buyerIdentity: {
            countryCode: country.toUpperCase(),
          },
        },
      },
    });

    return response.data.data.cartCreate.cart.checkoutUrl;
  } catch (error) {
    console.log(error);
    return null;
  }
}

import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "../../css/Club.css";
import { AccountContext } from "../../context/account";
import { t } from "../../helpers";

const HowTo = React.memo(() => {
  const { region } = useContext(AccountContext);

  if (!region) {
    return <div></div>;
  }
  return (
    <>
      {region && (
        <section className="club-howto">
          <h1>{t("new_landing_club.howto_title")}</h1>
          <div className="howto-list">
            <div className="howto-inner">
              <span>1</span>
              <div className="text">
                <p>
                  {t("new_landing_club.howto_1", {
                    price: region.extra.club_price,
                  })}
                </p>
              </div>
            </div>
            <div className="howto-inner">
              <span>2</span>
              <div className="text">
                <p>{t("new_landing_club.howto_2")}</p>
              </div>
            </div>
            <div className="howto-inner">
              <span>3</span>
              <div className="text">
                <p>{t("new_landing_club.howto_3")}</p>
              </div>
            </div>
            <div className="howto-inner">
              <span>4</span>
              <div className="text">
                <p>{t("new_landing_club.howto_4")}</p>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
});

export default HowTo;

import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "./css/index.css";
import ProtectedRoute from "./components/ProtectedRoute";
import { AccountContext, AccountContextProvider } from "./context/account";
import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { captureException } from "@sentry/browser";
import { urlToLocale } from "./helpers";
import { getCountryRegion } from "./logic/regions";
import Error404 from "./screens/Error404";
import SubscriptionUpsells from "./screens/account/subscriptions/SubscriptionUpsells";
import SubscriptionRefill from "./screens/account/subscriptions/SubscriptionRefill";
import Account from "./screens/account/Account";
import AccountSubscriptions from "./screens/account/AccountSubscriptions";
import AccountSubscriptionsSingle from "./screens/account/AccountSubscriptionsSingle";
import Login from "./screens/Login";
import LandingExperienceGourmet from "./screens/LandingExperienceGourmet";
import ClubLandingNew from "./screens/LandingClub";
import AccountPaymentMethods from "./screens/account/AccountPaymentMethods";
import AccountNewPaymentMethod from "./screens/account/AccountNewPaymentMethod";
import Home from "./screens/LandingClub";

const enviroment = process.env.REACT_APP_ENV || "live";

if (enviroment) {
  Sentry.init({
    dsn: "https://fd24ca8d56ed468483ccbb24367f5119@o127200.ingest.sentry.io/6146982",
    integrations: [new TracingIntegrations.BrowserTracing()],
    environment: enviroment,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

function RouteMiddleware() {
  const location = useLocation();
  const { region, setRegion } = useContext(AccountContext);
  useEffect(() => {
    const [lang, country] = urlToLocale(location.pathname);

    if (!region) {
      getCountryRegion(country)
        .then((response) => {
          if (response.status === 200) {
            let regionData = response.data;
            if (!regionData.extra.club_info || !regionData.extra.club_price) {
              getCountryRegion("XX")
                .then((response) => {
                  if (response.status === 200) {
                    regionData.extra.club_info = response.data.extra.club_info;
                    regionData.extra.club_price =
                      response.data.extra.club_price;
                    regionData.use_world = true;
                    setRegion(regionData);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              setRegion(regionData);
            }
          }
        })
        .catch(() => {});
    }
  }, [location]);

  return <span></span>;
}

function AppRouter() {
  useEffect(() => {
    function amm() {
      try {
        var s, d;
        s = localStorage.getItem("amm");
        d = JSON.parse(s);
        if (d) {
          return d;
        }
      } catch (err) {
        console.log(err);
      }
    }

    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", "1983837231849232", amm());
    fbq("track", "PageView");

    (function (i, s, o, g, r, a, m) {
      i["GoogleAnalyticsObject"] = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      "script",
      "https://www.google-analytics.com/analytics.js",
      "ga"
    );

    ga("create", "UA-42631937-5", "auto");
    ga("require", "GTM-NJNWVNZ");
    ga("require", "ec");
    document.addEventListener("DOMContentLoaded", function () {
      ga("send", "pageview");
    });

    (function () {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://182114.tracking.hyros.com/v1/lst/universal-script?ph=55518a23778d3981fb75ba8a5956c64dbd125a52aab869d88e7c13cd0fc0c75e&tag=!tracking";
      script.defer = true;
      document.getElementsByTagName("body")[0].appendChild(script);
    })();

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.defer = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-KW3SVVL");
  }, []);
  return (
    <React.Suspense fallback={<span></span>}>
      <AccountContextProvider>
        <Router>
          <RouteMiddleware />
          <Switch>
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/account"
              render={(props) => (
                <>
                  <React.Suspense fallback={<span></span>}>
                    <ProtectedRoute
                      path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/subscriptions/list"
                      component={AccountSubscriptions}
                    />
                    <ProtectedRoute
                      {...props}
                      path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/subscriptions/:type/:uuid/upsells"
                      component={SubscriptionUpsells}
                    />
                    <ProtectedRoute
                      {...props}
                      path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/subscriptions/:type/:uuid/refill"
                      component={SubscriptionRefill}
                    />
                    <ProtectedRoute
                      {...props}
                      path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/subscriptions/:type/:uuid/"
                      component={AccountSubscriptionsSingle}
                      exact
                    />
                    <ProtectedRoute
                      path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/payment_methods/new"
                      component={AccountNewPaymentMethod}
                    />
                    <ProtectedRoute
                      path="/:lang([a-z]{2})-:country([a-z]{2})/account/profile/payment_methods"
                      component={AccountPaymentMethods}
                    />
                    <ProtectedRoute
                      {...props}
                      path={`/:lang([a-z]{2})-:country([a-z]{2})/account/`}
                      component={Account}
                      exact
                    />
                  </React.Suspense>
                </>
              )}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/login"
              component={Login}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/club"
              component={ClubLandingNew}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/club-new"
              component={ClubLandingNew}
            />
            <Route
              path="/:lang([a-z]{2})-:country([a-z]{2})/"
              component={Home}
            />
            <Route path="/" component={Error404} />
          </Switch>
        </Router>
      </AccountContextProvider>
    </React.Suspense>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById("root")
);

import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "../../css/Club.css";
import { AccountContext } from "../../context/account";
import Button from "../../components/Button";
import { t } from "../../helpers";

const Advantages = React.memo(() => {
  const { region } = useContext(AccountContext);

  if (!region) {
    return <div></div>;
  }
  return (
    <>
      {region && (
        <section className="club-advantages">
          <h1> {t("new_landing_club.advantages_title")}</h1>
          <div className="advantages-list">
            <div className="advantages-inner">
              <span>1</span>
              <div className="text">
                <p>
                  {t("new_landing_club.advantages_1", {
                    price: region.extra.club_price,
                  })}
                </p>
                <div className="line"></div>
              </div>
            </div>
            <div className="advantages-inner">
              <span>2</span>
              <div className="text">
                <p>{t("new_landing_club.advantages_2")}</p>
                <div className="line"></div>
              </div>
            </div>
            <div className="advantages-inner">
              <span>3</span>
              <div className="text">
                <p>{t("new_landing_club.advantages_3")}</p>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
});

export default Advantages;

import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "../../css/Club.css";
import { AccountContext } from "../../context/account";
import { t } from "../../helpers";

const Press = React.memo(() => {
  const { region } = useContext(AccountContext);

  if (!region) {
    return <div></div>;
  }
  return (
    <>
      {region && (
        <section className="club-press">
          <h1>{t("new_landing_club.press_title")}</h1>
          <img
            className="mobile"
            src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_022023_press_mobile.png"
          />
          <img
            style={{ marginBottom: "30px" }}
            className="desktop"
            src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_022023_press_desktop_starts.png"
          />
          <img
            className="desktop"
            src="https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_022023_press_press_new.png"
          />
        </section>
      )}
    </>
  );
});

export default Press;

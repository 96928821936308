import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "../../css/Club.css";
import { AccountContext } from "../../context/account";
import Button from "../../components/Button";
import { Redirect } from "react-router-dom";
import { t } from "../../helpers";

const Hero = React.memo(({ onClickCallback }) => {
  const { region } = useContext(AccountContext);
  let [redirectTo, setRedirectTo] = useState(null);

  if (redirectTo) {
    return <Redirect push to={redirectTo} />;
  }
  if (!region) {
    return <div></div>;
  }
  return (
    <>
      {region && (
        <section className="hero-club-landing">
          <div className="title">
            <p className="title-1">{t("new_landing_club.hero_1")}</p>
            <p className="title-2">
              <span className="for">{t("new_landing_club.hero_2")}</span>
              {region.use_world ? (
                <span className="price">
                  {" "}
                  {region.extra.club_price.replace("$", "")}
                  <span className="currency-month">
                    {" "}
                    <span class="currency">$</span>/
                    {t("new_landing_club.hero_month")}
                  </span>
                </span>
              ) : (
                <span className="price">
                  {" "}
                  {region.extra.club_price.replace(region.symbol, "")}
                  <span className="currency-month">
                    {" "}
                    <span className="currency">{region.symbol}</span>/
                    {t("new_landing_club.hero_month")}
                  </span>
                </span>
              )}
            </p>
          </div>
          <div className="hero-buttons">
            <Button
              style={{ backgroundColor: "black" }}
              onClick={() => {
                onClickCallback();
              }}
            >
              <span>{t("new_landing_club.hero_choose_product")}</span>
            </Button>
            <Button
              style={{ backgroundColor: "black" }}
              type="default"
              onClick={() => {
                setRedirectTo("account/?c=0");
              }}
            >
              <span>{t("new_landing_club.hero_member")}</span>
            </Button>
          </div>
        </section>
      )}
    </>
  );
});

export default Hero;
